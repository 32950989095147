<div class="bg-white p-xl-5 p-2">
  <div class="border-bottom border-gray">
    <button
      type="button"
      class="close float-left"
      aria-label="Close"
      (click)="cancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <h3 *ngIf="group?.type === 'CONSULTING_INT'">Ajouter un utilisateur</h3>
    <h3 *ngIf="isCreate && group?.type === 'NOTARY_OFFICE'">
      Création d'un notaire
    </h3>
    <h3 *ngIf="!isCreate && group?.type === 'NOTARY_OFFICE'">
      Modification d'un notaire
    </h3>
    <h3 *ngIf="isCreate && group?.type !== 'NOTARY_OFFICE'">
      Création d'un utilisateur
    </h3>
    <h3 *ngIf="!isCreate && group?.type !== 'NOTARY_OFFICE'">
      Modification d'un utilisateur
    </h3>
  </div>
  <form *ngIf="isReady" (ngSubmit)="onSubmit()" [formGroup]="detailsFormGroup">
    <div class="row mt-5" *ngIf="group.type !== 'CONSULTING_INT'">
      <div class="form-group text-secondary col-12">
        <small
          class="form-text text-danger"
          *ngIf="submitted && detailsFormGroup.invalid"
          >Tous les champs en rouge sont obligatoires.</small
        >
      </div>
      <div class="form-group text-secondary col-12">
        <small
          class="form-text text-danger"
          *ngIf="submitted && mailAlreadyTaken"
          >Un utilisateur avec la même adresse email existe déjà. Merci
          d'utiliser une adresse email unique. Si vous pensez qu'il s'agit d'une
          erreur, veuillez contacter l'adresse
          successions&#64;grandlyon.com</small
        >
      </div>
      <div class="col-xl-12">
        <h5 *ngIf="group?.type === 'NOTARY_OFFICE'">Informations du Notaire</h5>
        <h5 *ngIf="group?.type !== 'NOTARY_OFFICE'">
          Informations de l'utilisateur
        </h5>
        <div class="form-group text-secondary">
          <input
            [class.is-invalid]="submitted && f.fullname.errors"
            class="form-control my-1"
            formControlName="fullname"
            placeholder="Prénom Nom"
          />
        </div>
        <div class="form-group text-secondary">
          <input
            [class.is-invalid]="submitted && f.mail.errors"
            class="form-control my-1"
            formControlName="mail"
            placeholder="Adresse email"
            [attr.disabled]="!isCreate ? '' : null"
            onkeyup="this.value=this.value.toLowerCase();"
          />
          <small
            class="text-danger"
            *ngIf="submitted && f.mail?.errors?.pattern"
            >Merci de saisir une adresse email.</small
          >
          <small
            class="text-danger"
            *ngIf="submitted && f.mail?.errors?.maxlength"
            >Merci de saisir une adresse email de moins de 50 caractères.</small
          >
        </div>
        <div class="form-group text-secondary">
          <input
            [class.is-invalid]="submitted && f.tel.errors"
            class="form-control my-1"
            formControlName="tel"
            placeholder="Téléphone"
          />
          <small class="text-danger" *ngIf="submitted && f.tel?.errors?.pattern"
            >Le format du numéro de téléphone est incorrect.</small
          >
          <small
            class="text-danger"
            *ngIf="submitted && f.tel?.errors?.maxlength"
            >Merci de saisir 10 chiffres uniquement.</small
          >
        </div>
        <div class="form-group text-secondary">
          <input
            [class.is-invalid]="submitted && f.password.errors"
            class="form-control my-1"
            formControlName="password"
            placeholder="Mot de passe"
            type="password"
            [attr.disabled]="!isCreate ? '' : null"
          />
          <small
            class="form-text text-danger"
            *ngIf="submitted && f.password.errors?.passwordValidator"
          >
            Le mot de passe doit contenir au moins 16 caractères, une majuscule,
            une minuscule, un caractère spécial et un chiffre.
          </small>
        </div>
      </div>
    </div>

    <div
      class="form-group text-secondary overflow-auto"
      *ngIf="group.type === 'CONSULTING_INT'"
    >
      <h5 class="mt-2">liste des agents sans affectation :</h5>
      <ul
        class="list-group"
        *ngIf="
          !isModificationMode ||
          !detailsFormGroup.controls.referee.value ||
          detailsFormGroup.controls.referee.value === ''
        "
      >
        <li
          class="list-group-item"
          *ngFor="let user of agents; let index = index"
        >
          <input
            (click)="onUserSelect(user)"
            class="form-check-input my-1"
            type="radio"
            [value]="user.email"
            [id]="'checkbox' + index"
            name="mail"
          />
          <label class="form-check-label" [for]="'checkbox' + index">{{
            user.name
          }}</label>
        </li>
        <li *ngIf="!agents || agents.length === 0">
          Aucun utilisateur sans affectations n'a été trouvé ...
        </li>
      </ul>
      <ul
        class="list-group"
        *ngIf="
          isModificationMode &&
          detailsFormGroup.controls.referee.value &&
          detailsFormGroup.controls.referee.value !== ''
        "
      >
        <li class="list-group-item">
          {{ detailsFormGroup.controls.referee.value }}
        </li>
        <li class="list-group-item">
          {{ detailsFormGroup.controls.mail.value }}
        </li>
        <li class="list-group-item">
          {{ detailsFormGroup.controls.tel.value }}
        </li>
      </ul>
    </div>

    <div class="row px-3 pt-3">
      <div class="col-6 d-md-none d-lg-block col-lg-6 mb-1"></div>
      <div class="col-3 col-md-6 col-lg-3 mb-1">
        <button class="button" type="button" (click)="cancel()">
          <fa-icon icon="ban"></fa-icon>Annuler
        </button>
      </div>
      <div class="col-3 col-md-6 col-lg-3 mb-1">
        <button class="button button-primary" type="submit">
          <fa-icon icon="save"></fa-icon>Sauvegarder
        </button>
      </div>
    </div>
  </form>
</div>
