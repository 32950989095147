<span class="px-5">
  <h3>Bienvenue sur PANOS !</h3>
  <h3>la Plateforme d'Aide aux Notaires pour les Successions</h3>
</span>
<ng-container>
  <div class="row pt-3">
    <div class="col-xl-1"></div>
    <div class="col-xl-10">
      <div
        class="alert alert-warning"
        role="alert"
        *ngIf="globalParameters && globalParameters.displayAlert"
      >
        <div class="d-flex flex-row">
          <fa-icon
            class="d-none d-lg-inline"
            icon="exclamation-triangle"
            size="2x"
          ></fa-icon
          >&nbsp;

          <quill-view
            [content]="globalParameters?.alertMessage"
            theme="snow"
          ></quill-view>
        </div>
      </div>
    </div>
    <div class="col-xl-1"></div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="row pt-3">
        <div class="col-xl-2"></div>
        <div class="col-xl-10">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                <fa-icon
                  class="d-none d-lg-inline"
                  icon="home"
                  size="1x"
                ></fa-icon
                >&nbsp;Accueil Panos
              </h5>
            </div>
            <div class="card-body">
              <p class="card-text" *ngIf="!currentUser?.isNotary">
                <quill-view
                  [content]="globalParameters?.agentMessage"
                  theme="snow"
                ></quill-view>
              </p>
              <p class="card-text" *ngIf="currentUser?.isNotary">
                <quill-view
                  [content]="globalParameters?.notaryMessage"
                  theme="snow"
                ></quill-view>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row pt-3">
        <div class="col-xl-10">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title" *ngIf="!currentUser?.isNotary">
                <fa-icon
                  class="d-none d-lg-inline"
                  icon="table-columns"
                  size="1x"
                ></fa-icon
                >&nbsp;Tableau de bord
              </h5>
              <h5 class="card-title" *ngIf="currentUser?.isNotary">
                <fa-icon
                  class="d-none d-lg-inline"
                  icon="folder-open"
                  size="1x"
                ></fa-icon
                >&nbsp;Mes dossiers
              </h5>
            </div>
            <div id="stats" class="card-body">
              <ul>
                <li>
                  Dossiers en cours :
                  <strong>{{ foldersStats?.openedFolders }}</strong>
                </li>
                <ul>
                  <li *ngIf="isIncludeAid('ASH')">
                    Dossiers avec de l'ASH :
                    <strong
                      ><a
                        [class.disabled]="foldersStats?.ASHFolders === 0"
                        [routerLink]="
                          foldersStats?.ASHFolders === 0 ? [] : ['/folders']
                        "
                        [queryParams]="
                          foldersStats?.ASHFolders === 0
                            ? {}
                            : { socialAid: 'ASH', onlyOpen: true }
                        "
                        [attr.aria-disabled]="
                          foldersStats?.ASHFolders === 0 ? true : null
                        "
                      >
                        {{ foldersStats?.ASHFolders }}
                      </a></strong
                    >
                  </li>
                  <li *ngIf="isIncludeAid('APA')">
                    Dossiers avec l'APA :
                    <strong
                      ><a
                        [class.disabled]="foldersStats?.APAFolders === 0"
                        [routerLink]="
                          foldersStats?.APAFolders == 0 ? [] : ['/folders']
                        "
                        [queryParams]="
                          foldersStats?.APAFolders == 0
                            ? {}
                            : { socialAid: 'APA', onlyOpen: true }
                        "
                        [attr.aria-disabled]="
                          foldersStats?.APAFolders == 0 ? true : null
                        "
                      >
                        {{ foldersStats?.APAFolders }}
                      </a></strong
                    >
                  </li>
                  <li *ngIf="isIncludeAid('PCH')">
                    Dossiers avec de la PCH :
                    <strong
                      ><a
                        [class.disabled]="foldersStats?.PCHFolders === 0"
                        [routerLink]="
                          foldersStats?.PCHFolders === 0 ? [] : ['/folders']
                        "
                        [queryParams]="
                          foldersStats?.PCHFolders === 0
                            ? {}
                            : { socialAid: 'PCH', onlyOpen: true }
                        "
                        [attr.aria-disabled]="
                          foldersStats?.PCHFolders === 0 ? true : null
                        "
                      >
                        {{ foldersStats?.PCHFolders }}
                      </a></strong
                    >
                  </li>
                  <li *ngIf="isIncludeAid('AC')">
                    Dossiers avec de la AC :
                    <strong
                      ><a
                        [class.disabled]="foldersStats?.ACFolders === 0"
                        [routerLink]="
                          foldersStats?.ACFolders === 0 ? [] : ['/folders']
                        "
                        [queryParams]="
                          foldersStats?.ACFolders === 0
                            ? {}
                            : { socialAid: 'AC', onlyOpen: true }
                        "
                        [attr.aria-disabled]="
                          foldersStats?.ACFolders === 0 ? true : null
                        "
                      >
                        {{ foldersStats?.ACFolders }}
                      </a></strong
                    >
                  </li>

                  <li *ngIf="isIncludeAid('AM')">
                    Dossiers avec de la AM :
                    <strong
                      ><a
                        [class.disabled]="foldersStats?.AMFolders === 0"
                        [routerLink]="
                          foldersStats?.AMFolders === 0 ? [] : ['/folders']
                        "
                        [queryParams]="
                          foldersStats?.AMFolders === 0
                            ? {}
                            : { socialAid: 'AM', onlyOpen: true }
                        "
                        [attr.aria-disabled]="
                          foldersStats?.AMFolders === 0 ? true : null
                        "
                      >
                        {{ foldersStats?.AMFolders }}
                      </a></strong
                    >
                  </li>

                  <li *ngIf="currentUser?.isMetropole">
                    Dossiers en attente de calcul de créance réelle :
                    <strong>
                      <a
                        [class.disabled]="foldersStats?.waitingRealClaim === 0"
                        [routerLink]="
                          foldersStats?.waitingRealClaim === 0
                            ? []
                            : ['/folders']
                        "
                        [queryParams]="
                          getFolderQueryParams('waitingRealClaim', 3)
                        "
                        [attr.aria-disabled]="
                          foldersStats?.waitingRealClaim === 0 ? true : null
                        "
                      >
                        {{ foldersStats?.waitingRealClaim }}
                      </a></strong
                    >
                  </li>
                  <li *ngIf="currentUser?.isNotary">
                    Dossiers en attente de calcul du Solde de la Successions :
                    <strong>
                      <a
                        [class.disabled]="
                          foldersStats?.waitingNetEstateBalance === 0
                        "
                        [routerLink]="
                          foldersStats?.waitingNetEstateBalance === 0
                            ? []
                            : ['/folders']
                        "
                        [queryParams]="
                          getFolderQueryParams('waitingNetEstateBalance', 4)
                        "
                        [attr.aria-disabled]="
                          foldersStats?.waitingNetEstateBalance === 0
                            ? true
                            : null
                        "
                      >
                        {{ foldersStats?.waitingNetEstateBalance }}
                      </a>
                    </strong>
                  </li>
                  <li
                    *ngIf="
                      currentUser?.isMetropole &&
                      (isIncludeAid('AM') ||
                        isIncludeAid('AC') ||
                        isIncludeAid('APA') ||
                        isIncludeAid('ASH') ||
                        isIncludeAid('PCH'))
                    "
                  >
                    Dossiers en attente de titrage :
                    <strong>
                      <a
                        [class.disabled]="
                          foldersStats?.waitingComptability === 0
                        "
                        [routerLink]="
                          foldersStats?.waitingComptability === 0
                            ? []
                            : ['/folders']
                        "
                        [queryParams]="
                          getFolderQueryParams('waitingComptability', 5)
                        "
                        [attr.aria-disabled]="
                          foldersStats?.waitingComptability === 0 ? true : null
                        "
                      >
                        {{ foldersStats?.waitingComptability }}
                      </a>
                    </strong>
                  </li>

                  <li *ngIf="currentUser?.isNotary">
                    Dossiers en attente de paiement :
                    <strong>
                      <a
                        [class.disabled]="foldersStats?.waitingPayment === 0"
                        [routerLink]="
                          foldersStats?.waitingPayment === 0 ? [] : ['/folders']
                        "
                        [queryParams]="
                          getFolderQueryParams('waitingPayment', 6)
                        "
                        [attr.aria-disabled]="
                          foldersStats?.waitingPayment === 0 ? true : null
                        "
                      >
                        {{ foldersStats?.waitingPayment }}
                      </a>
                    </strong>
                  </li>

                  <li *ngIf="currentUser?.isMetropole">
                    Dossiers en attente de paiement depuis plus de 2 mois :
                    <strong>{{ foldersStats?.waitingPayment2Months }}</strong>
                  </li>

                  <li *ngIf="currentUser?.isMetropole">
                    Dossiers en attente de calcul de créance réelle depuis plus
                    de 3 mois :
                    <strong>{{ foldersStats?.waitingRealClaim3Months }}</strong>
                  </li>
                </ul>

                <li
                  *ngIf="
                    (currentUser?.isMetropole &&
                      currentUser?.group?.handleAmbiguous) ||
                    currentUser?.isNotary ||
                    currentUser?.isAdmin
                  "
                >
                  Dossiers ambigus :
                  <strong>
                    <a
                      [class.disabled]="foldersStats?.ambiguousFolders === 0"
                      [routerLink]="
                        foldersStats?.ambiguousFolders === 0 ? [] : ['/folders']
                      "
                      [queryParams]="
                        getFolderQueryParams('ambiguousFolders', 1)
                      "
                      [attr.aria-disabled]="
                        foldersStats?.ambiguousFolders === 0 ? true : null
                      "
                    >
                      {{ foldersStats?.ambiguousFolders }}
                    </a>
                  </strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-2"></div>
      </div>
    </div>
  </div>
</ng-container>
