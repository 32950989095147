<nav
  class="navbar navbar-expand-lg navbar-dark bg-primary fixed-left pl-1 pr-0"
>
  <div class="collapse navbar-collapse" id="navbarNav" *ngIf="user">
    <ul class="navbar-nav pr-2 pt-sm-5">
      <li
        class="nav-item text-center py-md-3 py-xl-3"
        routerLinkActive="active"
        ngbDropdown
        id="user-menu"
      >
        <a
          class="nav-link pl-0 active font-weight-bold"
          (click)="clickUser()"
          tabindex="-1"
          ngbDropdownToggle
        >
          <fa-icon class="d-block" icon="user-circle" size="2x"></fa-icon
          >{{ user?.name }}
        </a>
        <div
          class="logout"
          [ngClass]="{ hidden: !openUser }"
          (click)="clickUser()"
        >
          <button
            class="text-secondary"
            (click)="disconnect()"
            routerLink="/login"
          >
            <fa-icon icon="power-off" class="mr-2"></fa-icon>Déconnexion
          </button>
        </div>
      </li>
      <li
        class="nav-item text-center py-md-1 py-xl-2"
        routerLinkActive="active"
      >
        <a class="nav-link px-0" (click)="openUser = false" routerLink="/home">
          <fa-icon class="d-none d-lg-block" icon="home" size="2x"></fa-icon
          >Accueil
        </a>
      </li>
      <li
        class="nav-item text-center py-md-1 py-xl-2"
        routerLinkActive="active"
        *ngIf="user.isAdmin || user.isNotary"
      >
        <a
          class="nav-link px-0"
          (click)="openUser = false"
          routerLink="/beneficiaries"
        >
          <fa-icon
            class="d-none d-lg-block"
            icon="folder-plus"
            size="2x"
          ></fa-icon
          >Création de dossier
        </a>
      </li>
      <li
        class="nav-item text-center py-md-1 py-xl-2"
        routerLinkActive="active"
      >
        <a
          class="nav-link px-0"
          (click)="openUser = false"
          routerLink="/folders"
        >
          <fa-icon
            class="d-none d-lg-block"
            icon="folder-open"
            size="2x"
          ></fa-icon
          >Dossiers
        </a>
      </li>
      <li
        class="nav-item text-center py-md-1 py-xl-2"
        routerLinkActive="active"
        *ngIf="user.isAdmin || user.groups.includes('CONSULTING_INT')"
      >
        <a class="nav-link px-0" routerLink="/stats">
          <fa-icon
            class="d-none d-lg-block"
            icon="chart-area"
            size="2x"
          ></fa-icon
          >Statistiques
        </a>
      </li>
      <li
        class="nav-item text-center py-md-1 py-xl-2"
        routerLinkActive="active"
        *ngIf="user.isAdmin || user.isReferee"
      >
        <a class="nav-link px-0" (click)="openUser = false" routerLink="/admin">
          <fa-icon class="d-none d-lg-block" icon="cogs" size="2x"></fa-icon
          >Admin
        </a>
      </li>
    </ul>
  </div>
</nav>
