<div class="row mt-5">
  <div class="col-1"></div>
  <div class="col-6" id="desc">
    <h1>Panos</h1>
    <h2>À qui s'adresse Panos ?</h2>
    <p>
      PANOS est une application web à destination des notaires, afin de
      faciliter leurs échanges avec la Métropole de Lyon dans le suivi des
      successions concernant les aides sociales suivantes :
    </p>
    <ul>
      <li>Allocation Personnalisée d'Autonomie (APA)</li>
      <li>Prestation de Compensation du Handicap (PCH)</li>
      <li>Aide Sociale à l'Hébergement (ASH)</li>
      <li>Aide Compensatrice (AC)</li>
      <li>Aide Ménagère (AM)</li>
    </ul>
    <h2>Comment fonctionne Panos ?</h2>
    <p>
      Après saisie par le notaire des informations contenues dans l'acte de
      décès, PANOS compare ces dernières avec celles des bénéficiaires d’aide
      sociale métropolitains<br />
      En cas de doute, un agent métropolitain se chargera de lever l'ambiguïté
      dans les plus brefs délais. Dans la plupart des cas, les notaires ont une
      réponse immédiate qu'elle soit positive ou négative.<br />
      Les échanges de documents se font ensuite au travers de la plateforme,
      facilitant le suivi entre l'office notarial et l’unité récupération de la
      Métropole de Lyon.
    </p>
  </div>
  <div class="col-4">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <h2>Connexion</h2>
      <div class="form-group text-secondary">
        <label>Adresse email</label>
        <input
          type="text"
          class="form-control"
          formControlName="email"
          onkeyup="this.value=this.value.toLowerCase();"
          [class.is-invalid]="submitted && loginForm.controls?.email?.invalid"
        />
        <small
          *ngIf="submitted && loginForm.controls?.email?.errors?.required"
          class="form-text text-danger"
          >L'adresse email est requise.</small
        >
        <small
          *ngIf="submitted && loginForm.controls?.email?.errors?.email"
          class="form-text text-danger"
          >L'adresse email est invalide.</small
        >
      </div>
      <div class="form-group text-secondary">
        <label>Mot de passe</label>
        <input
          type="password"
          class="form-control"
          formControlName="password"
          [class.is-invalid]="
            submitted && loginForm.controls?.password?.invalid
          "
        />
        <small
          *ngIf="submitted && loginForm.controls?.password?.errors?.required"
          class="form-text text-danger"
          >Le mot de passe est requis.</small
        >
      </div>
      <div id="connect-zone">
        <div>
          <a routerLink="/login/forget">Mot de passe oublié ?</a>
        </div>
        <div>
          <button type="submit" class="button button-primary">
            Se connecter
          </button>
        </div>
      </div>
      <div *ngIf="hasLoginFailed">
        <div>
          <ngb-alert type="danger" (close)="resetLoginFailed()"
            >L'email et/ou le mot de passe sont incorrects.
          </ngb-alert>
        </div>
      </div>
      <div id="connect-oidc-zone">
        <div>
          <hr />
          <div class="form-group text-secondary mt-5">
            <button
              type="button"
              class="button"
              (click)="onClickExternalLogin()"
            >
              <fa-icon icon="key"></fa-icon>
              Se connecter avec un compte Métropole
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-1"></div>
</div>
