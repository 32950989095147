import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private isLoadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  private isLoading: boolean = false;

  public isLoading$: Observable<boolean> = this.isLoadingSub.asObservable();

  public showLoader(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      this.isLoadingSub.next(this.isLoading);
    }
  }

  public hideLoader(): void {
    if (this.isLoading) {
      this.isLoading = false;
      this.isLoadingSub.next(this.isLoading);
    }
  }
}
