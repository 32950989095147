import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { SortDirection, SortEvent } from './sort-event';

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class SortableDirective {
  private static rotate: { [key: string]: SortDirection } = {
    asc: 'desc',
    desc: '',
    '': 'asc',
  };

  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  public rotate() {
    this.direction = SortableDirective.rotate[this.direction];
    this.sort.emit(new SortEvent(this.sortable, this.direction));
  }
}
