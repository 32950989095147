<div class="row mt-5">
  <div class="col-xl-1"></div>
  <div class="col-xl-10">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <h5>Paramètres généraux</h5>

      <div class="mb-3">
        <label class="form-label">Message d'accueil pour les notaires :</label>
        <quill-editor
          class="quill-editor-container"
          #notaryMessageEditor
          [formControl]="formGroup.get('notaryMessage')"
          (onContentChanged)="onContentChanged('notaryMessage', 1000)"
          (onEditorCreated)="onContentChanged('notaryMessage', 1000)"
        ></quill-editor>
        <small
          *ngIf="notaryMessageLimitExceeded"
          class="text-danger fst-italic"
        >
          *Le texte surligné en rouge dépasse la limite autorisée.
        </small>
      </div>

      <div class="mb-3">
        <label class="form-label">Message d'accueil pour les agents :</label>
        <quill-editor
          class="quill-editor-container"
          #agentMessageEditor
          [formControl]="formGroup.get('agentMessage')"
          (onContentChanged)="onContentChanged('agentMessage', 1000)"
          (onEditorCreated)="onContentChanged('agentMessage', 1000)"
        ></quill-editor>
        <small *ngIf="agentMessageLimitExceeded" class="text-danger fst-italic">
          *Le texte surligné en rouge dépasse la limite autorisée.
        </small>
      </div>

      <div class="mb-3">
        <label class="form-label custom-control-label" for="displayAlert">
          <input
            type="checkbox"
            class="custom-control-input"
            formControlName="displayAlert"
            name="displayAlert"
            id="displayAlert"
          />
          <span class="checkmark">X</span> Afficher un message d'alerte aux
          utilisateurs</label
        ><br />
        <label class="form-label">Message d'alerte :</label><br />
        <quill-editor
          class="quill-editor-container"
          #alertMessageEditor
          [formControl]="formGroup.get('alertMessage')"
          (onContentChanged)="onContentChanged('alertMessage', 500)"
          (onEditorCreated)="onContentChanged('alertMessage', 500)"
        ></quill-editor>
        <small *ngIf="alertMessageLimitExceeded" class="text-danger fst-italic">
          *Le texte surligné en rouge dépasse la limite autorisée.
        </small>
      </div>

      <button class="button" type="submit" [disabled]="!isFormValid()">
        Sauvegarder
      </button>
    </form>
  </div>
  <div class="col-xl-1"></div>
</div>
