import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ToastService } from '../../shared/toast/toast.service';
import { TitleService } from '../../shared/title/title.service';

@Component({
  selector: 'app-forget-pwd',
  templateUrl: './forget-pwd.component.html',
  styleUrls: ['./forget-pwd.component.scss'],
})
export class ForgetPwdComponent implements OnInit {
  public submitted = false;
  public forgetPwd: FormGroup;
  public hasForgetFailed = false;

  constructor(
    private formBuilder: FormBuilder,
    private titleService: TitleService,
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService,
  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle('Réinitialiser le mot de passe de votre compte');
    this.forgetPwd = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public async onSubmit(): Promise<void> {
    this.submitted = true;
    if (!this.forgetPwd.invalid) {
      this.hasForgetFailed = false;
      try {
        await this.authService.forgetPassword(
          this.forgetPwd.controls.email.value,
        );
        this.toastService.show(
          'Un email vous a bien été envoyé pour réinitialiser votre mot de passe.',
          'success',
        );
        this.router.navigate(['/login']);
      } catch (error) {
        this.hasForgetFailed = true;
      }
    }
  }

  public resetForgetFailed(): void {
    this.hasForgetFailed = false;
  }
}
