<div class="bg-white m-2">
  <div class="border-bottom border-gray">
    <h3 class="text-center pb-2 pr-2">Confirmation</h3>
    <button
      type="button"
      class="close float-right"
      aria-label="Close"
      (click)="refuse()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <p class="col-12 text-secondary">{{ question }}</p>
      <div class="col-6 p-0 pr-1">
        <button class="button" type="button" (click)="refuse()">Non</button>
      </div>
      <div class="col-6 p-0 pl-1">
        <button class="button button-primary" type="button" (click)="accept()">
          Oui
        </button>
      </div>
    </div>
  </div>
</div>
