import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupDetailsDto } from '../office.details.dto';
import { AdminService } from '../../../admin.service';
import { UtilsService } from '../../../../shared/service/utils.service';
import { SocialAid } from 'src/shared/interface/socialAid.interface';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
})
export class OfficeDetailsComponent implements OnInit {
  public details: GroupDetailsDto;
  public detailsFormGroup: FormGroup;
  public submitted = false;
  public isModificationMode = false;
  public isCreate = true;
  public CRPCENExists = false;
  public selectedValue: FormGroup;
  public groupSelect: string = '';
  public titleModal: string;
  constructor(
    public readonly modal: NgbActiveModal,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private readonly utilsService: UtilsService
  ) { }

  public async ngOnInit(id?: number) {
    if (id > 0) {
      this.details = await this.adminService.getGroupDetails(id);
      if (this.details.handleAmbiguous) {
        this.details.socialAids.push({ id: 0, name: 'Ambigu' });
      }
      this.isModificationMode = true;
      this.groupSelect = this.details.type;
    } else {
      this.details = new GroupDetailsDto(
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        false,
        null,
        null,
        null,
        null
      );
    }

    this.detailsFormGroup = this.formBuilder.group({
      CRPCEN: [this.details.CRPCEN, Validators.required],
      label: [this.details.label, Validators.required],
      adress: [this.details.adress, Validators.required],
      adress2: [this.details.adress2],
      city: [this.details.city, Validators.required],
      postCode: [
        this.details.postCode,
        [Validators.required, Validators.pattern(this.utilsService.postalCodePattern)],
      ],
      referee: [this.details.referee, Validators.required],
      mail: [this.details.mail, [Validators.required, Validators.email, Validators.maxLength(50)]],
      tel: [
        this.details.tel,
        [
          Validators.required,
          Validators.pattern(this.utilsService.phoneNumberPattern),
          Validators.maxLength(10),
        ],
      ],
      password: [this.details.password, [Validators.required, this.utilsService.passwordValidator]],
      socialAids: [this.details.socialAids],
      accountingEmail: [this.details.accountingEmail, [Validators.email, Validators.maxLength(50)]],
    });
    if (
      this.isModificationMode &&
      this.details.referee &&
      this.details.referee !== ''
    ) {
      this.detailsFormGroup.controls.referee.disable();
      this.detailsFormGroup.controls.mail.disable();
      this.detailsFormGroup.controls.password.disable();
      this.detailsFormGroup.controls.socialAids.setValue(
        this.details.socialAids,
      );
    }
    this.setValidatorChangeGroup();
  }

  public setValidatorChangeGroup() {
    this.setTitleModal();
    if (this.groupSelect === 'NOTARY_OFFICE') {
      this.detailsFormGroup
        .get('postCode')
        .setValidators([Validators.required]);
      this.detailsFormGroup.get('city').setValidators([Validators.required]);
      this.detailsFormGroup.get('adress').setValidators([Validators.required]);
      this.detailsFormGroup.get('CRPCEN').setValidators([Validators.required]);
      this.detailsFormGroup.get('referee').setValidators([Validators.required]);
      this.detailsFormGroup
        .get('tel')
        .setValidators([
          Validators.required,
          Validators.pattern(this.utilsService.phoneNumberPattern),
          Validators.maxLength(10),
        ]);
      this.detailsFormGroup
        .get('password')
        .setValidators([Validators.required, this.utilsService.passwordValidator]);
      this.detailsFormGroup.get('socialAids').setValidators([]);
    } else {
      this.detailsFormGroup.get('adress').setValidators([]);
      this.detailsFormGroup.get('city').setValidators([]);
      this.detailsFormGroup.get('postCode').setValidators([]);

      this.detailsFormGroup.get('CRPCEN').setValidators([]);
      this.detailsFormGroup.get('referee').setValidators([]);
      this.detailsFormGroup.get('tel').setValidators([]);
      this.detailsFormGroup.get('password').setValidators([]);
      if (this.groupSelect === 'SOCIAL_AID') {
        this.detailsFormGroup
          .get('socialAids')
          .setValidators([Validators.required]);
      } else if (this.groupSelect === 'CONSULTING_EXT') {
        this.detailsFormGroup
          .get('adress')
          .setValidators([Validators.required]);
        this.detailsFormGroup
          .get('postCode')
          .setValidators([Validators.required]);
        this.detailsFormGroup
          .get('password')
          .setValidators([Validators.required, this.utilsService.passwordValidator]);
        this.detailsFormGroup.get('city').setValidators([Validators.required]);
      }
    }
    this.detailsFormGroup.get('city').updateValueAndValidity();
    this.detailsFormGroup.get('postCode').updateValueAndValidity();
    this.detailsFormGroup.get('adress').updateValueAndValidity();
    this.detailsFormGroup.get('CRPCEN').updateValueAndValidity();
    this.detailsFormGroup.get('referee').updateValueAndValidity();
    this.detailsFormGroup.get('tel').updateValueAndValidity();
    this.detailsFormGroup.get('password').updateValueAndValidity();
    this.detailsFormGroup.get('socialAids').updateValueAndValidity();
  }

  public setTitleModal() {
    if (this.groupSelect === 'NOTARY_OFFICE') {
      if (this.isModificationMode) {
        this.titleModal = `Modification de l'Office ${this.details.label}`;
      } else {
        this.titleModal = `Création d'un Office Notarial`;
      }
    } else {
      if (this.isModificationMode) {
        this.titleModal = `Modification du groupe ${this.details.label}`;
      } else {
        this.titleModal = `Création d'un groupe`;
      }
    }
  }

  public get f() {
    return this.detailsFormGroup.controls;
  }

  public async onSubmit() {
    this.submitted = true;
    this.CRPCENExists = false;
    if (this.detailsFormGroup.invalid || this.groupSelect === '') {
      return;
    } else {
      this.details.type = this.groupSelect;
      this.details.CRPCEN = this.detailsFormGroup.controls.CRPCEN.value;
      this.details.label = this.detailsFormGroup.controls.label.value;
      this.details.adress = this.detailsFormGroup.controls.adress.value;
      this.details.adress2 = this.detailsFormGroup.controls.adress2.value;
      this.details.city = this.detailsFormGroup.controls.city.value;
      this.details.postCode = this.detailsFormGroup.controls.postCode.value;
      this.details.referee = this.detailsFormGroup.controls.referee.value;
      this.details.mail = this.detailsFormGroup.controls.mail.value;
      this.details.tel = this.detailsFormGroup.controls.tel.value;
      this.details.password = this.detailsFormGroup.controls.password.value;
      this.details.accountingEmail = this.detailsFormGroup.controls.accountingEmail?.value;
      if (this.groupSelect === 'SOCIAL_AID') {
        this.details.socialAids = this.filterSocialAids();
      }
      this.details.handleAmbiguous =
        this.groupSelect === 'SOCIAL_AID' &&
        this.detailsFormGroup.controls.socialAids.value.some(
          (element: SocialAid) => element.id === 0,
        );

      try {
        this.isCreate = await this.adminService.saveGroup(this.details);
        if (this.isCreate) {
          this.modal.close(this.details);
          this.ngOnInit();
        } else {
          return;
        }
      } catch (error) {
        if (error.message === 'CRPCEN') {
          this.CRPCENExists = true;
        }
      }
    }
  }

  public cancel(): void {
    this.detailsFormGroup.reset();
    this.groupSelect = '';
    this.details = undefined;
    this.modal.close();
  }

  public filterSocialAids(): SocialAid[] {
    // filter Ambiguous socialAides
    return this.detailsFormGroup.controls.socialAids.value.filter(
      (element: any) => {
        return element.id !== 0;
      },
    );
  }
}
