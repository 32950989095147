<div class="row toasts py-1 mt-1">
  <div class="col">
    <ngb-alert
      *ngFor="let toast of toastService.toasts"
      [type]="toast.type"
      (close)="toastService.remove(toast)"
      >{{ toast.message }}</ngb-alert
    >
  </div>
</div>
