import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FolderDto } from 'src/folders/folder.dto';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    postalCodePattern: RegExp = /\d{5}/;
    phoneNumberPattern: RegExp = /\d{10}/;
    public decodeBase64(input: string): string {
        const keyStr: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        let output: string = '';
        let chr1: number, chr2: number, chr3: number;
        let enc1: number, enc2: number, enc3: number, enc4: number;
        let i: number = 0;
        const base64test = /[^A-Za-z0-9\+\/\=]/g;

        if (base64test.exec(input)) {
            throw new Error(
                'There were invalid base64 characters in the input text.\n' +
                "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
                'Expect errors in decoding.'
            );
        }
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

        do {
            enc1 = keyStr.indexOf(input.charAt(i++));
            enc2 = keyStr.indexOf(input.charAt(i++));
            enc3 = keyStr.indexOf(input.charAt(i++));
            enc4 = keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output += String.fromCharCode(chr1);

            if (enc3 !== 64) {
                output += String.fromCharCode(chr2);
            }
            if (enc4 !== 64) {
                output += String.fromCharCode(chr3);
            }
        } while (i < input.length);

        return decodeURIComponent(output);
    }

    passwordValidator(control: FormControl): { [key: string]: any } | null {
        const password = control.value;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
        const hasNumber = /\d/.test(password);

        if (!hasUpperCase || !hasLowerCase || !hasSpecialChar || !hasNumber || password.length < 16) {
            return { 'passwordValidator': true };
        }
        return null;
    }

    public getBracketedZeroPaddedDate(date: string): string {
        if (date === '' || date === null) return '';
        const now = typeof date === 'string' ? new Date(date) : date;
        const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
        const month = now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
        const year = now.getFullYear();
        return ` (${day}/${month}/${year})`;
    }

    public getDateWithOptionalSeparator(date: string, separator?: string): string {
        if (date === '' || date === null) return '';
        const now = typeof date === 'string' ? new Date(date) : date;
        const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();
        const month = now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
        const year = now.getFullYear();
        if (separator) {
            return `${day}${separator}${month}${separator}${year}`;
        } else {
            return ` ${day}${month}${year}`;
        }
    }

    public getFrenchFormattedDate(date: Date = new Date()): string {
        if (date && typeof date === 'string') {
            date = new Date(date);
        }
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };
        return date.toLocaleDateString('fr-FR', options);
    }

    public capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    public isInRangeNumber(value: number, min: number, max: number): boolean {
        return value >= min && value <= max;
    }

    public getReferencePanos(titrationNumber: string, folder: FolderDto): string {
        return titrationNumber && titrationNumber !== '0' ? `40000-${titrationNumber}_${folder?.searchResult?.beneficiary?.lastname}_${folder?.searchResult?.beneficiary?.firstName.toUpperCase()}_${this.getDateWithOptionalSeparator(folder?.searchResult?.beneficiary?.birthDate.toString())}` : null
    }
}
