<footer class="footer text-center">
  <div class="row">
    <div class="col">
      <span class="text-white pl-5"
        ><a href="/api/globalParameters/mentionslegale/"
          >MENTIONS LÉGALES</a
        ></span
      >
      <span class="text-white pl-3">|</span>
      <span class="text-white pl-3"
        ><a href="/api/globalParameters/besoinaide/">BESOIN D'AIDE</a></span
      >
    </div>
    <div class="col">
      <span class="text-white"
        >Une démarche partenariale pilotée par la
        <a
          href="https://www.grandlyon.com"
          target="_blank"
          rel="noopener noreferrer"
          class="text-primary"
          >Métropole de Lyon</a
        ></span
      >
    </div>
  </div>
</footer>
