<ng-container
  (ngSubmit)="onRowSubmit()"
  [formGroup]="rowFormGroup"
  #rowForm="ngForm"
>
  <td>
    <p class="pt-2 m-0">{{ mdm?.name }}</p>
  </td>
  <td>
    <input
      [class.is-invalid]="rowSubmitted && f.emailAC.errors"
      class="form-control"
      formControlName="emailAC"
      type="email"
    />

    <small class="text-danger pl-1" *ngIf="rowSubmitted && f.emailAC.errors"
      >format de mail invalide</small
    >
  </td>
  <td>
    <input
      [class.is-invalid]="rowSubmitted && f.emailAPA.errors"
      class="form-control"
      formControlName="emailAPA"
      type="email"
    />

    <small class="text-danger pl-1" *ngIf="rowSubmitted && f.emailAPA.errors"
      >format de mail invalide</small
    >
  </td>
  <td>
    <input
      [class.is-invalid]="rowSubmitted && f.emailPCH.errors"
      class="form-control"
      formControlName="emailPCH"
      type="email"
    />
    <small class="text-danger pl-1" *ngIf="rowSubmitted && f.emailPCH.errors"
      >format de mail invalide</small
    >
  </td>
  <td>
    <input
      [class.is-invalid]="rowSubmitted && f.emailHeadOfService.errors"
      class="form-control"
      formControlName="emailHeadOfService"
      type="email"
    />
    <small
      class="text-danger pl-1"
      *ngIf="rowSubmitted && f.emailHeadOfService.errors"
      >format de mail invalide</small
    >
  </td>
  <td>
    <input
      class="button"
      type="button"
      value="Sauvegarder"
      (click)="onRowSubmit()"
    />
  </td>
</ng-container>
