import { SocialAid } from "src/shared/interface/socialAid.interface";

export class GroupDetailsDto {
  constructor(
    public type: string,
    public label: string,
    public adress: string,
    public adress2: string,
    public city: string,
    public postCode: string,
    public CRPCEN: string,
    public referee: string,
    public mail: string,
    public tel: string,
    public password: string,
    public isModification?: boolean,
    public id?: number,
    public socialAids?: SocialAid[],
    public handleAmbiguous?: boolean,
    public accountingEmail?: string,
  ) { }
}
