import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TitleService } from '../../shared/title/title.service';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {
  public submitted = false;
  public loginForm: FormGroup;
  public hasLoginFailed = false;
  public returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private titleService: TitleService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    this.titleService.setTitle('Accueil');
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.authService?.getCurrentUser()?.subscribe(user => {
      if (user) {
        this.router.navigate(['/']);
      }
    });

    this.returnUrl =
      this.activatedRoute.snapshot.queryParamMap.get('returnUrl') || '/';
  }

  public async onSubmit(): Promise<void> {
    this.hasLoginFailed = false;
    this.submitted = true;
    if (!this.loginForm.invalid) {
      try {
        await this.authService.login(
          this.loginForm.value.email,
          this.loginForm.value.password,
        );
        this.router.navigate([this.returnUrl]);
      } catch (error) {
        this.hasLoginFailed = true;
      }
    }
  }

  public resetLoginFailed() {
    this.hasLoginFailed = false;
  }

  public async onClickExternalLogin(): Promise<void> {
    this.resetLoginFailed();
    await this.authService.externalLogin();
  }
}
