import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../shared/toast/toast.service';
import { UtilsService } from '../../shared/service/utils.service';

@Component({
  selector: 'app-external-login-callback',
  templateUrl: './callback.component.html',
})
export class CallbackComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly toastService: ToastService,
    private readonly utilsService: UtilsService

  ) { }

  public async ngOnInit() {
    const encryptedToken: string | null =
      this.activatedRoute.snapshot.queryParamMap.get('token');
    if (encryptedToken != null) {
      try {
        this.utilsService.decodeBase64(encryptedToken);
        await this.authService.externallLoginCallback();
      } catch (error) {
        this.toastService.show(
          'Une erreur est survenue lors de la connexion.',
          'danger',
        );
      }
    } else {
      this.toastService.show(
        "Vous n'êtes pas autorisé à vous connecter.",
        'danger',
      );
    }
    this.router.navigate(['/']);
  }
}
