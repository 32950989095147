import { Component, OnInit } from '@angular/core';
import { StatsForHomeDto } from 'src/shared/dto/statsForHome.dto';
import { GlobalParametersDto } from '../../admin/main/global/globalParameter.dto';
import { AuthService } from '../../auth/auth.service';
import { UserDto } from '../../auth/userProfileDto';
import { FoldersService } from '../../shared/service/folders.service';
import { TitleService } from '../../shared/title/title.service';
import { GlobalParameters } from '../home.service';
import { SocialAid } from 'src/shared/interface/socialAid.interface';
import { FolderStatusEnum } from 'src/shared/enum/folderStatus.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public currentUser: UserDto;
  public globalParameters: GlobalParametersDto;
  public foldersStats: StatsForHomeDto;

  constructor(
    private authService: AuthService,
    private titleService: TitleService,
    private globalParametersService: GlobalParameters,
    private foldersService: FoldersService,
  ) { }

  public async ngOnInit() {
    this.authService.getCurrentUser().subscribe({
      next: (user) => {
        this.currentUser = user;

        this.getStatsHome();

      },
    });

    this.globalParameters =
      await this.globalParametersService.getGlobalParameters();
    this.titleService.setTitle('Accueil');
  }

  public async getStatsHome(): Promise<void> {
    this.foldersStats = await this.foldersService.getStatsForHome();
  }

  public isIncludeAid(aid: string): boolean {
    if (this.currentUser.isAdmin || this.currentUser.isNotary) return true
    else return !!this.currentUser?.group?.socialAids?.find((x: SocialAid) => x.name === aid);
  }

  public getFolderQueryParams(folderStatProperty: keyof StatsForHomeDto, folderStatus: FolderStatusEnum) {
    return this.foldersStats?.[folderStatProperty] === 0
      ? {}
      : {
        folderStatus,
        ...(this.currentUser?.isAdmin ? {} : { groupId: this.currentUser?.group?.id })
      };
  }
}
