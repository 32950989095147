import { Injectable } from '@angular/core';
import { Toast } from './toast';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public readonly TOAST_DURATION_MS: number = 3000;
  public toasts: Toast[] = [];

  public show(
    message: string,
    type:
      | 'success'
      | 'info'
      | 'warning'
      | 'danger'
      | 'primary'
      | 'secondary'
      | 'light'
      | 'dark',
  ): void {
    const toast: Toast = new Toast(message, type);
    this.toasts.push(toast);
    setTimeout(() => {
      this.remove(toast);
    }, this.TOAST_DURATION_MS);
  }

  public remove(toast: Toast): void {
    this.toasts = this.toasts.filter((t: Toast) => t !== toast);
  }
}
