export class Toast {
  constructor(
    public message: string,
    public type:
      | 'success'
      | 'info'
      | 'warning'
      | 'danger'
      | 'primary'
      | 'secondary'
      | 'light'
      | 'dark',
  ) {}
}
