import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AdminService } from 'src/admin/admin.service';
import { UserDto } from 'src/auth/userProfileDto';

@Component({
  selector: 'app-consulting-form',
  templateUrl: './consulting-form.component.html',
})

export class ConsultingFormComponent implements OnInit {
  @Input() group: string;
  @Input() detailsFormGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() isModificationMode: boolean;
  @Input() isCreate: boolean;
  @Input() CRPCENExists: boolean;
  @Output() onSubmit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  public users: UserDto[];
  public switchBtn: boolean = false;

  constructor(public adminService: AdminService) { }
  public get f() {
    return this.detailsFormGroup.controls;
  }
  async ngOnInit() {
    this.users = await this.adminService.getMetropoleUsers();
    this.users = this.users.filter((user) => !user.groupId);
  }
  emitEventSubmit() {
    this.onSubmit.emit();
  }

  emitEventCancel() {
    this.cancel.emit();
  }
}
