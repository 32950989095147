<div>
  <div id="menu-admin">
    <div
      class="menu-tab"
      *ngIf="currentUser.isAdmin && currentUser.isMetropole"
      [ngClass]="{ active: currentTab === 'global' }"
      (click)="open('global')"
    >
      <h5>Général</h5>
    </div>
    <div
      class="menu-tab"
      *ngIf="currentUser.isAdmin && currentUser.isMetropole"
      [ngClass]="{ active: currentTab === 'metropole' }"
      (click)="open('metropole')"
    >
      <h5>Utilisateurs sans affectations</h5>
    </div>
    <div
      class="menu-tab"
      *ngIf="currentUser.isAdmin && currentUser.isMetropole"
      [ngClass]="{ active: currentTab === 'mdm' }"
      (click)="open('mdm')"
    >
      <h5>MDML</h5>
    </div>
    <div
      class="menu-tab"
      *ngIf="currentUser.isAdmin || currentUser.isReferee"
      [ngClass]="{ active: currentTab === 'office' }"
      (click)="open('office')"
    >
      <h5 *ngIf="currentUser && !currentUser.isNotary">Groupes</h5>
      <h5 *ngIf="currentUser && currentUser.isNotary">Notaires</h5>
    </div>
  </div>
  <div>
    <app-global *ngIf="currentTab === 'global'"> </app-global>
    <app-metropole *ngIf="currentTab === 'metropole'"> </app-metropole>
    <app-mdm *ngIf="currentTab === 'mdm'"> </app-mdm>
    <app-office *ngIf="currentTab === 'office'"> </app-office>
  </div>
</div>
