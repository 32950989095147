import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SortableDirective } from './sortable/sortable.directive';
import { ToastsContainerComponent } from './toast/toasts-container.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    SortableDirective,
    ToastsContainerComponent,
    ConfirmationComponent,
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgbTooltipModule,
  ],
  exports: [
    NgSelectModule,
    NgbModule,
    SortableDirective,
    ToastsContainerComponent,
    ConfirmationComponent,
    NgbTooltipModule,
  ],
})
export class SharedModule { }
