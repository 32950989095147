import { Component, OnInit } from '@angular/core';
import { UserDto } from 'src/auth/userProfileDto';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public user: UserDto | undefined = undefined;
  public openUser: boolean = false;

  constructor(private readonly authService: AuthService) {}

  public ngOnInit() {
    this.authService
      .getCurrentUser()
      .subscribe((userProfile: UserDto | undefined) => {
        this.user = userProfile;
      });
  }

  public disconnect(): void {
    this.clickUser();
    this.authService.logout();
  }

  public clickUser(): void {
    this.openUser = !this.openUser;
  }
}
