<div class="row mt-5">
  <div class="col-xl-2"></div>
  <div class="col-xl-8 mb-3">
    <table
      aria-describedby="Liste des mdm de la métropoles enregistrées dans Panos"
    >
      <thead>
        <tr>
          <th scope="col">Mdml</th>
          <th scope="col">Mail AC</th>
          <th scope="col">Mail APA</th>
          <th scope="col">Mail PCH</th>
          <th scope="col">Mail chef de service</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody *ngIf="mdms?.length > 0">
        <tr
          *ngFor="let mdm of mdms"
          app-mdm-row
          [mdm]="mdm"
          (mdmUpdated)="ngOnInit()"
        ></tr>
      </tbody>
      <tbody *ngIf="mdms?.length == 0">
        <tr>
          <td></td>
          <td>Aucune MDM</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-xl-2"></div>
</div>
