<form (ngSubmit)="emitEventSubmit()" [formGroup]="detailsFormGroup">
  <div class="row mt-5">
    <div class="form-group text-secondary col-12">
      <small
        class="form-text text-danger"
        *ngIf="submitted && detailsFormGroup.invalid"
        >Tous les champs en rouge sont obligatoires.</small
      >
    </div>
    <div class="form-group text-secondary col-12">
      <small
        class="form-text text-danger"
        *ngIf="submitted && !isCreate && !CRPCENExists"
        >Un utilisateur avec la même adresse email existe déjà. Merci d'utiliser
        une adresse email unique</small
      >
      <small class="form-text text-danger" *ngIf="submitted && CRPCENExists"
        >Ce numéro CRPCEN est déjà utilisé par un office.</small
      >
    </div>
    <div class="col-xl-12">
      <h5 *ngIf="groupSelect === 'NOTARY_OFFICE'">
        Informations de l'Office Notarial
      </h5>
      <h5 *ngIf="groupSelect !== 'NOTARY_OFFICE'">Informations du groupe</h5>
      <div class="form-group text-secondary">
        <input
          [class.is-invalid]="submitted && f.CRPCEN.errors"
          class="form-control my-1"
          formControlName="CRPCEN"
          placeholder="Numéro CRPCEN"
        />
      </div>
      <div class="form-group text-secondary">
        <input
          [class.is-invalid]="submitted && f.label.errors"
          class="form-control my-1"
          formControlName="label"
          [placeholder]="
            groupSelect === 'NOTARY_OFFICE'
              ? 'Nom de l\'office'
              : 'Nom du groupe'
          "
        />
      </div>
      <div class="form-group text-secondary">
        <input
          [class.is-invalid]="submitted && f.adress.errors"
          class="form-control my-1"
          formControlName="adress"
          placeholder="Adresse"
        />
      </div>
      <div class="form-group text-secondary">
        <input
          class="form-control my-1"
          formControlName="adress2"
          placeholder="Complément d'adresse"
        />
      </div>
      <div class="form-group text-secondary">
        <input
          [class.is-invalid]="submitted && f.city.errors"
          class="form-control my-1"
          formControlName="city"
          placeholder="Ville"
        />
      </div>
      <div class="form-group text-secondary">
        <input
          [class.is-invalid]="submitted && f.postCode.errors"
          class="form-control my-1"
          formControlName="postCode"
          placeholder="Code postal"
          maxlength="5"
        />
        <small
          class="text-danger"
          *ngIf="submitted && f.postCode?.errors?.pattern"
          >Merci de saisir 5 chiffres uniquement.</small
        >
      </div>
      <h5 *ngIf="groupSelect === 'NOTARY_OFFICE'">
        Informations du Notaire référent
      </h5>
      <h5 *ngIf="groupSelect === 'CONSULTING_EXT'">
        Informations du Consultant référent
      </h5>
      <div
        *ngIf="
          !isModificationMode ||
            (detailsFormGroup?.controls?.referee?.invalid &&
              isModificationMode);
          else elseBlock
        "
      >
        <div class="form-group text-secondary">
          <input
            [class.is-invalid]="submitted && f.referee.errors"
            class="form-control my-1"
            formControlName="referee"
            placeholder="Prénom Nom"
          />
        </div>
        <div class="form-group text-secondary">
          <input
            [class.is-invalid]="submitted && f.mail.errors"
            class="form-control my-1"
            formControlName="mail"
            placeholder="Adresse email"
            onkeyup="this.value=this.value.toLowerCase();"
          />
          <small
            class="text-danger"
            *ngIf="
              submitted &&
              (f.mail?.errors?.email ||
                f.mail?.errors?.invalid ||
                f.mail?.errors?.maxlength)
            "
            >Merci de saisir une adresse email valide et de moins de 50
            caractères.</small
          >
        </div>
        <div class="form-group text-secondary">
          <input
            [class.is-invalid]="submitted && f.tel.errors"
            class="form-control my-1"
            formControlName="tel"
            placeholder="Téléphone"
          />
          <small class="text-danger" *ngIf="submitted && f.tel?.errors?.pattern"
            >Le format du numéro de téléphone est incorrect.</small
          >
          <small
            class="text-danger"
            *ngIf="submitted && f.tel?.errors?.maxlength"
            >Merci de saisir 10 chiffres uniquement.</small
          >
        </div>
        <div class="form-group text-secondary">
          <input
            [class.is-invalid]="submitted && f.password.errors"
            class="form-control my-1"
            formControlName="password"
            placeholder="Mot de passe"
            type="password"
          />
          <small
            class="form-text text-danger"
            *ngIf="submitted && f.password.errors?.passwordValidator"
          >
            Le mot de passe doit contenir au moins 16 caractères, une majuscule,
            une minuscule, un caractère spécial et un chiffre.
          </small>
        </div>
      </div>

      <h5 *ngIf="groupSelect === 'NOTARY_OFFICE'">
        Informations du Service comptable
      </h5>

      <div
        *ngIf="groupSelect === 'NOTARY_OFFICE'"
        class="form-group text-secondary"
      >
        <input
          [class.is-invalid]="submitted && f.accountingEmail.errors"
          class="form-control my-1"
          formControlName="accountingEmail"
          placeholder="Adresse email"
          onkeyup="this.value=this.value.toLowerCase();"
        />
        <small
          class="text-danger"
          *ngIf="
            submitted &&
            (f.accountingEmail?.errors?.email ||
              f.accountingEmail?.errors?.maxlength)
          "
          >Merci de saisir une adresse email valide de moins de 50
          caractères.</small
        >
      </div>
      <ng-template #elseBlock>
        <ul class="list-group" *ngIf="isModificationMode">
          <li class="list-group-item">
            {{ detailsFormGroup.controls.referee.value }}
          </li>
          <li class="list-group-item">
            {{ detailsFormGroup.controls.mail.value }}
          </li>

          <li class="list-group-item">
            {{ detailsFormGroup.controls.tel.value }}
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
  <div class="row px-3 pt-3">
    <div class="col-6 d-md-none d-lg-block col-lg-6 mb-1"></div>
    <div class="col-3 col-md-6 col-lg-3 mb-1">
      <button class="button" type="button" (click)="emitEventCancel()">
        <fa-icon icon="ban"></fa-icon>Annuler
      </button>
    </div>
    <div class="col-3 col-md-6 col-lg-3 mb-1">
      <button class="button button-primary" type="submit">
        <fa-icon icon="save"></fa-icon>Sauvegarder
      </button>
    </div>
  </div>
</form>
