<form (ngSubmit)="emitEventSubmit()" [formGroup]="detailsFormGroup">
  <div class="row mt-4">
    <div class="form-group text-secondary col-12">
      <small
        class="form-text text-danger"
        *ngIf="submitted && detailsFormGroup.invalid"
        >Tous les champs en rouge sont obligatoires.</small
      >
    </div>
    <div class="form-group text-secondary col-12">
      <small
        class="form-text text-danger"
        *ngIf="submitted && !isCreate && !CRPCENExists"
        >Un utilisateur avec la même adresse email existe déjà. Merci d'utiliser
        une adresse email unique</small
      >
    </div>
    <div class="col-xl-12">
      <h5>Informations du groupe</h5>
      <div class="form-group text-secondary">
        <input
          [class.is-invalid]="submitted && f.label.errors"
          class="form-control my-1"
          formControlName="label"
          placeholder="Nom du groupe"
        />
      </div>
      <div class="form-group text-secondary">
        <input
          [class.is-invalid]="submitted && f.adress.errors"
          class="form-control my-1"
          formControlName="adress"
          placeholder="Adresse"
        />
      </div>
      <div class="form-group text-secondary">
        <input
          class="form-control my-1"
          formControlName="adress2"
          placeholder="Complément d'adresse"
        />
      </div>
      <div class="form-group text-secondary">
        <input
          [class.is-invalid]="submitted && f.city.errors"
          class="form-control my-1"
          formControlName="city"
          placeholder="Ville"
        />
      </div>
      <div class="form-group text-secondary">
        <input
          [class.is-invalid]="submitted && f.postCode.errors"
          class="form-control my-1"
          formControlName="postCode"
          placeholder="Code postal"
          maxlength="5"
        />
        <small
          class="text-danger"
          *ngIf="submitted && f.postCode?.errors?.pattern"
          >Merci de saisir 5 chiffres uniquement.</small
        >
      </div>
      <h5 class="mt-3">
        Informations du référent
        <span *ngIf="submitted && f.mail?.errors?.required">*</span>
      </h5>

      <small class="text-danger" *ngIf="submitted && f.mail?.errors?.required"
        >Merci de choisir un reférent</small
      >
      <div class="form-group text-secondary overflow-auto" id="refereeUserList">
        <ul
          class="list-group"
          *ngIf="
            !isModificationMode ||
            !detailsFormGroup.controls.referee.value ||
            detailsFormGroup.controls.referee.value === ''
          "
        >
          <li
            class="list-group-item"
            *ngFor="let user of users; let index = index"
          >
            <input
              class="form-check-input my-1"
              type="radio"
              [value]="user.email"
              [id]="'checkbox' + index"
              formControlName="mail"
              name="mail"
            />
            <label class="form-check-label" [for]="'checkbox' + index">{{
              user.name
            }}</label>
          </li>
          <li *ngIf="!users || users.length === 0">
            Aucun utilisateur sans affectations n'a été trouvé ...
          </li>
        </ul>
        <ul
          class="list-group"
          *ngIf="
            isModificationMode &&
            detailsFormGroup.controls.referee.value &&
            detailsFormGroup.controls.referee.value !== ''
          "
        >
          <li class="list-group-item">
            {{ detailsFormGroup.controls.referee.value }}
          </li>
          <li class="list-group-item">
            {{ detailsFormGroup.controls.mail.value }}
          </li>
          <li class="list-group-item">
            {{ detailsFormGroup.controls.tel.value }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row px-3 pt-3">
    <div class="col-6 d-md-none d-lg-block col-lg-6 mb-1"></div>
    <div class="col-3 col-md-6 col-lg-3 mb-1">
      <button class="button" type="button" (click)="emitEventCancel()">
        <fa-icon icon="ban"></fa-icon>Annuler
      </button>
    </div>
    <div class="col-3 col-md-6 col-lg-3 mb-1">
      <button class="button button-primary" type="submit">
        <fa-icon icon="save"></fa-icon>Sauvegarder
      </button>
    </div>
  </div>
</form>
