import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { GlobalParametersDto } from './main/global/globalParameter.dto';
import { GroupTableDto } from './main/office/office.table.dto';
import { GroupDetailsDto } from './main/office/office.details.dto';
import { ApiService } from '../shared/api/api.service';
import { ToastService } from '../shared/toast/toast.service';
import { Mdm } from './mdm.entity';
import { HttpErrorResponse } from '@angular/common/http';
import { UserDto } from 'src/auth/userProfileDto';
import { AlphabeticalRangeDto } from 'src/shared/dto/alphabeticalRange.dto';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private readonly officeUpdate: Subject<GroupDetailsDto> =
    new Subject<GroupDetailsDto>();
  public officeUpdated$: Observable<GroupDetailsDto> =
    this.officeUpdate.asObservable();

  private readonly notaryUpdate: Subject<UserDto> = new Subject<UserDto>();
  public notaryUpdated$: Observable<UserDto> = this.notaryUpdate.asObservable();

  private readonly adminUpdate: Subject<void> = new Subject<void>();
  public adminUpdated$: Observable<void> = this.adminUpdate.asObservable();

  constructor(private api: ApiService, private toastService: ToastService) { }

  public async getMetropoleUsers(): Promise<UserDto[]> {
    return this.api.get<UserDto[]>('/api/users/metropole-users');
  }

  public async searchUsers(officeId: number): Promise<UserDto[]> {
    return this.api.get<UserDto[]>('/api/users/' + officeId + '/search');
  }

  public async getGroups(): Promise<any[]> {
    return this.api.get<any[]>('/api/groups');
  }

  public async getSocialAids(): Promise<any> {
    return this.api.get<UserDto[]>('/api/social-aid');
  }

  public async getglobalParameters(): Promise<GlobalParametersDto> {
    return this.api.get<GlobalParametersDto>('/api/globalParameters');
  }

  public async setGlobalParameters(
    notaryMessage: string,
    chiefOfficer: string,
    displayAlert: boolean,
    alertMessage: string,
    agentMessage: string,
  ): Promise<void> {
    try {
      const res = await this.api.post<any, GlobalParametersDto>(
        '/api/globalParameters/set',
        new GlobalParametersDto(
          notaryMessage,
          chiefOfficer,
          displayAlert,
          alertMessage,
          agentMessage,
        ),
      );
      this.toastService.show('Paramètres sauvegardés avec succès', 'success');
      return res;
    } catch (error) {
      this.toastService.show(
        'Erreur du serveur lors de la sauvegarde des paramètres',
        'danger',
      );
    }
  }

  public async saveGroup(group: GroupDetailsDto): Promise<boolean> {
    try {
      const res = await this.api.put<boolean, GroupDetailsDto>(
        '/api/groups/',
        group,
      );
      if (res) {
        this.officeUpdate.next(group);
        this.toastService.show(`Le groupe a bien été mis à jour'.`, 'success');
      } else {
        this.toastService.show(
          `Erreur : l'utilisateur' n'est pas administrateur`,
          'danger',
        );
      }
      return res;
    } catch (exception) {
      if (exception.status === 409) {
        throw new Error('CRPCEN');
      }
    }
  }

  public async disableGroup(officeId: number): Promise<boolean> {
    await this.api
      .delete<any>('/api/groups/' + officeId + '/delete')
      .catch((err: HttpErrorResponse) => {
        this.toastService.show(
          `Erreur lors de la désactivation du groupe`,
          'danger',
        );
        return false;
      });

    this.officeUpdate.next(
      new GroupDetailsDto('', '', '', '', '', '', '', '', '', '', ''),
    );
    this.toastService.show(`Office désactivé avec succès.`, 'success');
    return true;
  }

  public async getGroupDetails(officeId: number): Promise<GroupDetailsDto> {
    return this.api.get<GroupDetailsDto>(
      '/api/groups/' + officeId + '/details',
    );
  }

  public async getOffices(
    sortColumn?: string,
    sortDesc?: boolean,
  ): Promise<GroupTableDto[]> {
    let url = '/api/groups/byreferee';
    if (sortColumn !== undefined && sortDesc !== undefined) {
      url += '?sort=' + sortColumn;
      if (sortDesc) {
        url += '&desc=true';
      }
    }
    return this.api.get<GroupTableDto[]>(url);
  }

  public async saveUser(user: UserDto): Promise<boolean> {
    try {
      const notary = await this.api.post<boolean, UserDto>('/api/users/', user);
      if (notary) {
        this.notaryUpdate.next(user);

        this.toastService.show(`L'utilisateur a bien été créé.`, 'success');
      } else {
        this.toastService.show(
          `Erreur : l'utilisateur n'a pas été créé.`,
          'danger',
        );
      }
      return true;
    } catch (exception) {
      return false;
    }
  }

  public async updateUser(user: UserDto) {
    return this.api.put<UserDto, UserDto>('/api/users/', user);
  }
  async removeAphabeticalRange(range: AlphabeticalRangeDto) {
    return this.api.delete<AlphabeticalRangeDto | Error>('api/alphabetical_ranges/' + range.id);
  }
  public async addAlphabeticalRange(range: AlphabeticalRangeDto) {
    return this.api.post<AlphabeticalRangeDto, AlphabeticalRangeDto | Error>('api/alphabetical_ranges/', range);
  }

  public async setIsActiveUser(user: UserDto): Promise<void> {
    user = await this.api.put<UserDto, UserDto>('api/users/', user);
    if (user) {
      this.notaryUpdate.next(user);
      if (user.isActive && !user.isMetropole)
        this.toastService.show(`L'utilisateur a bien été réactivé.`, 'success');
      else this.toastService.show(`L'utilisateur a bien été désactivé.`, 'success');

    } else {
      this.toastService.show(
        `Erreur lors de la désactivation de l'utilisateur`,
        'danger',
      );
    }
  }

  public async setReferee(user: UserDto): Promise<void> {
    user = await this.api.put<UserDto, UserDto>('api/users/', user);
    if (user) {
      this.notaryUpdate.next(user);
      this.toastService.show(
        `Le changement de référent a été effectué avec succès.`,
        'success',
      );
    } else {
      this.toastService.show(`Erreur lors du changement de référent`, 'danger');
    }
  }

  public async getUser(id: number): Promise<UserDto> {
    return this.api.get<UserDto>('api/users/' + id);
  }

  public async getMdms(): Promise<Mdm[]> {
    return this.api.get<Mdm[]>('api/mdm/');
  }

  public async updateMdm(mdm: Mdm): Promise<void> {
    mdm = await this.api.put<Mdm, Mdm>('api/mdm/' + mdm.id, mdm);
    if (mdm) {
      this.toastService.show(
        `Le changement d'email a été effectué avec succès.`,
        'success',
      );
    } else {
      this.toastService.show(`Erreur lors du changement de mail`, 'danger');
    }
  }
}
