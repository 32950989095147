import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../shared/title/title.service';
import { Subscription } from 'rxjs';
import { DateService } from '../../shared/date/date.service';
import { VersionService } from '../../shared/service/version.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public pageTitle: string;
  public currentDate: Date = new Date();
  public version: string;
  private updateDateTimer: ReturnType<typeof setInterval>;
  private titleSubscription: Subscription;

  constructor(
    private titleService: TitleService,
    private dateService: DateService,
    private versionService: VersionService,
  ) {}

  public async ngOnInit() {
    this.titleSubscription = this.titleService
      .getTitle()
      .subscribe((title: string) => {
        this.pageTitle = title;
      });

    this.updateDateTimer = setInterval(() => {
      this.currentDate = this.dateService.getDate();
    }, 1000);

    const versionFront: string = require('./../../../package.json').version;
    const versionBack: string = await this.versionService.getVersion();
    this.version = 'FRONT : ' + versionFront + ' BACK : ' + versionBack;
  }

  public ngDestroy(): void {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
    }
    if (this.updateDateTimer) {
      clearInterval(this.updateDateTimer);
    }
  }
}
