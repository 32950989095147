import { Component, OnInit } from '@angular/core';
import { UserDto } from 'src/auth/userProfileDto';
import { AuthService } from '../../auth/auth.service';
import { TitleService } from '../../shared/title/title.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public currentUser: UserDto;
  public currentTab: string = 'global';

  constructor(
    private titleService: TitleService,
    private authService: AuthService,
  ) {}

  public async ngOnInit() {
    this.authService.getCurrentUser().subscribe({
      next: (user) => {
        this.currentUser = user;
      },
    });
    this.titleService.setTitle('Administration');
    if (!this.currentUser.isAdmin) {
      this.currentTab = 'office';
    }
  }

  public open(tab: string) {
    this.currentTab = tab;
  }
}
