import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from '../../shared/validators/must-match.validator';
import { AuthService } from '../auth.service';
import { ToastService } from '../../shared/toast/toast.service';
import { TitleService } from '../../shared/title/title.service';
import { DateService } from '../../shared/date/date.service';
import { getTime } from 'date-fns';
import { UtilsService } from '../../shared/service/utils.service';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
})
export class ResetPwdComponent implements OnInit {
  public submitted = false;
  public resetPwd: FormGroup;
  public hasResetFailed = false;
  private currentResetToken: string | undefined;

  constructor(
    private titleService: TitleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dateService: DateService,
    private authService: AuthService,
    private toastService: ToastService,
    private utilsService: UtilsService
  ) { }

  public ngOnInit(): void {
    this.titleService.setTitle('Réinitialiser le mot de passe de votre compte');
    this.resetPwd = new FormGroup(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, this.utilsService.passwordValidator]),
        confirmPassword: new FormControl('', [Validators.required, this.utilsService.passwordValidator]),
      },
      CustomValidators.mustMatch('password', 'confirmPassword'),
    );

    // load token from url data
    this.currentResetToken = this.getResetToken();
    if (!this.currentResetToken) {
      this.resetPwd.disable();
    }
    // remove url data
    this.router.navigate(['/login/reset']);
  }

  public async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.resetPwd.enabled && !this.resetPwd.invalid) {
      this.hasResetFailed = false;
      try {
        await this.authService.resetPassword(
          this.resetPwd.controls.email.value,
          this.currentResetToken,
          this.resetPwd.controls.password.value,
        );
        this.toastService.show(
          'Votre mot de passe a bien été modifié.',
          'success',
        );
        this.router.navigate(['/login']);
      } catch (error) {
        this.hasResetFailed = true;
      }
    }
  }

  public resetResetFailed(): void {
    this.hasResetFailed = false;
  }

  private getResetToken(): string | undefined {
    try {
      const urlDataHash: string | null =
        this.activatedRoute.snapshot.queryParamMap.get('token');
      if (urlDataHash) {
        // retrieve data from url
        const urlData = this.utilsService.decodeBase64(urlDataHash);
        const data: any = JSON.parse(urlData);
        // retrive token and expiration (unix epoch time) from url data
        const token: string | undefined = data.token;
        const expiration: number | undefined = data.expiration;
        if (token && token.length > 0 && expiration && expiration > 0) {
          // check token is not expired yet
          const timestamp = getTime(this.dateService.getDate()) / 1000;
          const currentDate: number = timestamp;
          if (currentDate < expiration) {
            return token;
          }
        }
      } else { return undefined; }
    } catch (error) {
      console.error('Invalid token hash');
    }
  }
}
