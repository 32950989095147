<td>
  <input
    (change)="setCurrentUsersForBulkAction(user)"
    type="checkbox"
    class="form-check-input"
    id="chekboxUser"
    [checked]="isContainUser(user)"
  />
</td>
<td>
  <p class="pt-2 m-0">{{ user?.name }}</p>
</td>
<td>
  <p class="pt-2 m-0">{{ user.isAdmin ? 'Administrateur' : 'Agent' }}</p>
</td>
<td>
  <input
    class="button button-small"
    type="button"
    value="{{ user.isAdmin ? 'Retirer admin' : 'Rendre admin' }}"
    (click)="onSetAdminSubmit()"
  />
  <input
    class="button button-small"
    type="button"
    value="Désactiver"
    (click)="onDisableSubmit()"
    [disabled]="user?.name === currentUser?.name"
  />
</td>
