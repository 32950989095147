import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faBan,
  faChartBar,
  faCogs,
  faExclamationTriangle,
  faFolderOpen,
  faHome,
  faKey,
  faPowerOff,
  fas,
  faSave,
  faSearch,
  faUpload,
  faUserCircle,
  faUsers,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { AdminModule } from '../admin/admin.module';
import { AuthInterceptor } from '../auth/auth.interceptor';
import { AuthModule } from '../auth/auth.module';
import { CoreModule } from '../core/core.module';
import { LoaderHttpInterceptor } from '../loader/loader.interceptor';
import { LoaderModule } from '../loader/loader.module';
import { LoaderService } from '../loader/loader.service';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FontAwesomeModule,
    AppRoutingModule,
    CoreModule,
    AuthModule,
    AdminModule,
    SharedModule,
    LoaderModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderHttpInterceptor,
      multi: true,
      deps: [LoaderService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(
      faUserCircle,
      faSearch,
      faFolderOpen,
      faChartBar,
      faCogs,
      faHome,
      faUsers,
      faPowerOff,
      faSave,
      faKey,
      faBan,
      faUpload,
      faExclamationTriangle,
      faXmarkCircle,
    );
  }
}
