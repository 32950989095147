<div class="row mt-5">
  <div class="col-12 col-lg-6 col-xl-4 mx-auto">
    <form [formGroup]="resetPwd" (ngSubmit)="onSubmit()">
      <div class="form-group text-secondary">
        <label>Adresse email</label>
        <input
          type="text"
          class="form-control"
          formControlName="email"
          [class.is-invalid]="submitted && resetPwd.controls?.email?.invalid"
          onkeyup="this.value=this.value.toLowerCase();"
        />
        <small
          *ngIf="submitted && resetPwd.controls?.email?.errors?.required"
          class="form-text text-danger"
          >L'adresse email est requise.</small
        >
        <small
          *ngIf="submitted && resetPwd.controls?.email?.errors?.email"
          class="form-text text-danger"
          >L'adresse email est invalide.</small
        >
      </div>
      <div class="form-group text-secondary">
        <label>Nouveau mot de passe</label>
        <input
          type="password"
          class="form-control"
          formControlName="password"
          [class.is-invalid]="submitted && resetPwd.controls?.password?.invalid"
        />
        <small
          *ngIf="submitted && resetPwd.controls?.password?.errors?.required"
          class="form-text text-danger"
          >Le mot de passe est requis.</small
        >
        <small
          *ngIf="submitted && resetPwd.controls?.password?.errors?.mustMatch"
          class="form-text text-danger"
          >Les mots de passe ne sont pas identiques.</small
        >
      </div>

      <div class="form-group text-secondary">
        <label>Confirmer le mot de passe</label>
        <input
          type="password"
          class="form-control"
          formControlName="confirmPassword"
          [class.is-invalid]="
            submitted && resetPwd.controls?.confirmPassword?.invalid
          "
        />
        <small
          *ngIf="
            submitted && resetPwd.controls?.confirmPassword?.errors?.required
          "
          class="form-text text-danger"
          >Le mot de passe est requis.</small
        >
        <small
          *ngIf="
            submitted && resetPwd.controls?.confirmPassword?.errors?.mustMatch
          "
          class="form-text text-danger"
          >Les mots de passe ne sont pas identiques.</small
        >
        <small
          class="form-text text-danger"
          *ngIf="
            submitted &&
            (resetPwd.controls?.password?.errors?.passwordValidator ||
              resetPwd.controls?.confirmPassword?.errors?.passwordValidator)
          "
        >
          Le mot de passe doit contenir au moins 16 caractères, une majuscule,
          une minuscule, un caractère spécial et un chiffre.
        </small>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 col-xl-6 offset-lg-6 offset-xl-6">
          <button type="submit" class="button" [disabled]="resetPwd.disabled">
            Soumettre
          </button>
        </div>
      </div>
      <div class="row mt-3" *ngIf="resetPwd.disabled">
        <div class="col">
          <ngb-alert type="danger" [dismissible]="false"
            >Ce lien de réinitialisation de mot de passe est invalide ou
            expiré.</ngb-alert
          >
        </div>
      </div>
      <div class="row mt-3" *ngIf="hasResetFailed">
        <div class="col">
          <ngb-alert type="danger" (close)="resetResetFailed()"
            >L'email et/ou le lien de réinitialisation sont incorrects ou
            invalides.</ngb-alert
          >
        </div>
      </div>
    </form>
  </div>
</div>
