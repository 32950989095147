import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { ReplaySubject, Observable, throwError } from 'rxjs';
import { LoaderService } from './loader.service';
import { map, catchError, finalize } from 'rxjs/operators';

export class LoaderHttpInterceptor implements HttpInterceptor {
  private pendingRequests = 0;
  private pendingRequestsStatus: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);

  public get pendingRequestsStatus$(): Observable<boolean> {
    return this.pendingRequestsStatus.asObservable();
  }
  constructor(private loaderService: LoaderService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.pendingRequests++;
    if (this.pendingRequests === 1) {
      this.pendingRequestsStatus.next(true);
      this.loaderService.showLoader();
    }

    return next.handle(req).pipe(
      map((event) => event),
      catchError((error) => throwError(() => error)),
      finalize(() => {
        this.pendingRequests--;
        if (this.pendingRequests === 0) {
          this.pendingRequestsStatus.next(false);
          this.loaderService.hideLoader();
        }
      }),
    );
  }
}
