<div class="row header p-2 border-bottom mb-2">
  <div class="col-xl-1 col-lg-2 col-md-2 col-sm-3 col-3">
    <div class="row">
      <div class="col-md-12 text-center">
        <span class="text-secondary font-weight-bold">{{
          currentDate | date : 'HH:mm'
        }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <span class="text-secondary">{{
          currentDate | date : 'dd/MM/yyyy'
        }}</span>
      </div>
    </div>
  </div>
  <div class="col-xl-5 col-lg-4 col-md-4 col-sm-9 col-9">
    <h4 class="mt-2 pl-5 border-left">{{ pageTitle }}</h4>
  </div>
  <div
    class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-2 text-right d-none d-md-block"
  >
    <img
      class="logo"
      src="assets/logo-grand-lyon-la-metropole.svg"
      alt=""
      [ngbTooltip]="version"
    />
  </div>
</div>
