import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api/api.service';
import { GlobalParametersDto } from '../admin/main/global/globalParameter.dto';

@Injectable({
  providedIn: 'root',
})
export class GlobalParameters {
  constructor(private api: ApiService) {}

  public async getGlobalParameters(): Promise<GlobalParametersDto> {
    return this.api.get<GlobalParametersDto>('/api/globalParameters');
  }
}
