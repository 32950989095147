import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api/api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { LocationService } from '../shared/service/location.service';
import { UserDto } from './userProfileDto';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUser: BehaviorSubject<UserDto | undefined> =
    new BehaviorSubject<UserDto | undefined>(undefined);

  constructor(
    private readonly location: LocationService,
    private readonly api: ApiService,
    private router: Router,

  ) {
    this.loadCurrentUser();
  }

  private async loadCurrentUser(): Promise<void> {
    const user = await this.api.get<UserDto>('/api/users/getCurrentUser', { withCredentials: true })
    if (user) {
      this.currentUser.next(user);
    } else {
      this.currentUser.next(undefined);
    }
  }

  public async login(email: string, password: string): Promise<void> {
    await this.api.post<any, any>('/api/auth/login', {
      email,
      password,
    }, { withCredentials: true });
    await this.loadCurrentUser();
  }

  public async forgetPassword(email: string): Promise<void> {
    await this.api.get<void>('/api/auth/forget?email=' + email);
  }

  public async resetPassword(
    email: string,
    token: string,
    password: string,
  ): Promise<void> {
    await this.api.post<void, any>('/api/auth/reset', {
      email,
      token,
      password,
    });
  }

  public async logout(): Promise<void> {
    await this.api.post<void, any>('/api/auth/logout', {}, { withCredentials: true });
    this.currentUser.next(undefined);
    this.router.navigate(['/login']); // Rediriger vers la page de login
  }

  public isLoggedIn(): boolean {
    return !!this.currentUser.value;
  }

  public getCurrentUser(): Observable<UserDto | undefined> {
    return this.currentUser.asObservable();
  }

  public async externalLogin(): Promise<void> {
    this.location.go('/api/auth/external');
  }

  public async externallLoginCallback(): Promise<void> {
    await this.loadCurrentUser();
  }
}
