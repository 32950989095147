import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../../admin.service';
import { OfficeComponent } from '../office.component';
import { UserDto } from '../../../../auth/userProfileDto';
import { GroupDetailsDto } from '../office.details.dto';
import { UtilsService } from '../../../../shared/service/utils.service';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
})
export class GroupDetailsComponent {
  public group: GroupDetailsDto;
  public notary: UserDto;
  public detailsFormGroup: FormGroup;
  public submitted = false;
  public isCreate: boolean;
  public parent: OfficeComponent;
  public mailAlreadyTaken: boolean = false;
  public isReady: boolean = false;
  public agents: UserDto[];
  selectedUser: UserDto | null = null;

  constructor(
    public readonly modal: NgbActiveModal,
    private adminService: AdminService,
    private formBuilder: FormBuilder,
    private readonly utilsService: UtilsService
  ) { }

  public async onInit(
    officeId: number,
    parent: OfficeComponent,
    notaryId?: number,
  ) {
    this.agents = (await this.adminService.getMetropoleUsers())?.filter((user) => !user.groupId);

    if (notaryId) {
      this.isCreate = false;
      this.notary = await this.adminService.getUser(notaryId);
    } else {
      this.isCreate = true;
    }
    if (!this.group)
      this.group = await this.adminService.getGroupDetails(officeId);
    this.initForm();
    this.isReady = true;
    this.parent = parent;
  }

  public get f() {
    return this.detailsFormGroup.controls;
  }

  public onUserSelect(user: UserDto) {
    this.selectedUser = user
  }

  public initForm() {
    this.detailsFormGroup = this.formBuilder.group({
      fullname: [this.notary?.name, Validators.required],
      mail: [this.notary?.email, [Validators.required, Validators.email, Validators.maxLength(50)]],
      tel: [
        this.notary?.phoneNumber,
        [
          Validators.required,
          Validators.pattern(this.utilsService.phoneNumberPattern),
          Validators.maxLength(10),
        ],
      ],
      password: ['', [Validators.required, this.utilsService.passwordValidator]],
    });

    if (!this.isCreate) {
      this.detailsFormGroup.controls.password.disable();
      if (this.notary.isMetropole) {
        this.detailsFormGroup.controls.mail.disable();
      }
    }
  }

  public async onSubmit() {
    this.submitted = true;
    if (this.detailsFormGroup.invalid && this.group.type !== 'CONSULTING_INT') return;

    if (this.isCreate && this.group.type !== 'CONSULTING_INT') {
      this.notary = {
        email: this.detailsFormGroup.value.mail,
        password: this.detailsFormGroup.value.password,
        name: this.detailsFormGroup.value.fullname,
        phoneNumber: this.detailsFormGroup.value.tel,
        groupId: this.group.id,
        isReferee: false,
        isAdmin: false,
        isMetropole: false,
        isActive: true,
      } as UserDto;
      if (this.group?.type === 'NOTARY_OFFICE') {
        this.notary.isNotary = true;
      } else {
        this.notary.isNotary = false;
      }
      let res = await this.adminService.saveUser(this.notary);
      if (res) {
        this.modal.close();
        await this.parent.refreshNotaries();
      } else {
        this.mailAlreadyTaken = true;
      }
    } else {
      if (this.group.type !== 'CONSULTING_INT') {
        this.notary.name = this.detailsFormGroup.value.fullname;
        this.notary.phoneNumber = this.detailsFormGroup.value.tel;
        this.notary.email = this.detailsFormGroup.value.mail;
      } else {
        this.notary = this.selectedUser
        this.notary.groupId = this.group.id
      }
      let res: any = await this.adminService.updateUser(this.notary);
      if (res?.message !== 'Error user') {
        this.modal.close();
        await this.parent.refreshNotaries();
      } else {
        this.mailAlreadyTaken = true;
      }
    }
  }

  public async cancel(): Promise<void> {
    this.detailsFormGroup.reset();
    this.notary = undefined;
    this.modal.close();
    await this.parent.refreshNotaries();
  }
}
