import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private currentTitle: BehaviorSubject<string> = new BehaviorSubject('');

  public getTitle(): Observable<string> {
    return this.currentTitle;
  }

  public setTitle(title: string): void {
    this.currentTitle.next(title);
  }
}
