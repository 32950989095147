import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserDto } from 'src/auth/userProfileDto';
import { AuthService } from '../../../../auth/auth.service';
import { ConfirmationComponent } from '../../../../shared/confirmation/confirmation.component';
import { AdminService } from '../../../admin.service';
import { GroupDto } from 'src/shared/dto/office.notary.dto';

@Component({
  selector: 'tr[app-user-row]',
  templateUrl: './user-row.component.html',
  styleUrls: ['./user-row.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserRowComponent),
      multi: true,
    },
  ],
})
export class UserRowComponent implements OnInit {
  @Output() usersUpdated = new EventEmitter();
  @Output() arrayChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() selectAll: boolean = false;
  @Input() user: UserDto;
  @Input() users: UserDto[];
  @Input() groupIdBulkAction: number;
  @Input() currentUsersForBulkAction: UserDto[];
  public rowFormGroup: FormGroup;
  public rowSubmitted = false;
  public currentUser: UserDto;
  public groups: GroupDto[];

  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private modalService: NgbModal,
  ) { }

  public async ngOnInit() {
    this.authService
      .getCurrentUser()
      .subscribe((user) => (this.currentUser = user));
    this.groups = await this.adminService.getGroups();
    this.groups = this.groups?.filter(
      (group) => group.type !== 'NOTARY_OFFICE' && group.isActive,
    );
  }

  public emitArrayChanges(): void {
    this.arrayChanged.emit(this.currentUsersForBulkAction);
  }

  public setCurrentUsersForBulkAction(user: UserDto) {
    const findUser = this.currentUsersForBulkAction.find(
      (usr) => usr.id === user.id,
    );
    if (findUser) {
      this.currentUsersForBulkAction = this.currentUsersForBulkAction.filter(
        (usr) => usr.id !== findUser.id,
      );
    } else {
      this.currentUsersForBulkAction.push(user);
    }
    this.arrayChanged.emit(this.currentUsersForBulkAction);
  }

  public isContainUser(user): boolean {
    return !!this.currentUsersForBulkAction.find((usr) => usr.id === user.id);
  }

  public async onRowSubmit() {
    this.rowSubmitted = true;
    this.user = await this.adminService.updateUser(this.user);
    this.usersUpdated.emit();
  }

  public async onDisableSubmit() {
    let confirm = await this.openDelConfirmationModal();
    if (confirm) {
      this.user.isActive = false;
      await this.adminService.updateUser(this.user);
      this.usersUpdated.emit();
    }
  }

  public async onSetAdminSubmit() {
    this.user.isAdmin = !this.user.isAdmin;
    await this.adminService.updateUser(this.user);
    this.usersUpdated.emit();
  }

  public get f() {
    return this.rowFormGroup.controls;
  }

  private async openDelConfirmationModal(): Promise<boolean> {
    const modal: NgbModalRef = this.modalService.open(ConfirmationComponent, {
      backdrop: 'static',
      centered: true,
    });
    const modalComponent: ConfirmationComponent =
      modal.componentInstance as ConfirmationComponent;
    return modalComponent.confirm(
      'Êtes-vous sûr de vouloir désactiver cet agent ?',
    );
  }
}
