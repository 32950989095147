import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { AuthModule } from '../auth/auth.module';
import { AdminRoutingModule } from './admin-router.module';
import { MetropoleComponent } from './main/metropole/metropole.component';
import { GlobalComponent } from './main/global/global.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserRowComponent } from './main/metropole/user-row/user-row.component';
import { OfficeComponent } from './main/office/office.component';
import { OfficeDetailsComponent } from './main/office/group-details/group-details.component';
import { HomeModule } from '../home/home.module';
import { SharedModule } from '../shared/shared.module';
import { GroupDetailsComponent } from './main/office/notary-details/group-details.component';
import { MdmComponent } from './main/mdm/mdm.component';
import { MdmRowComponent } from './main/mdm/mdm-row/mdm-row.component';
import { NotaryOfficeFormComponent } from './main/office/group-details/dynamic-group-forms/notary-office-form/notary-office-form.component';
import { SocialAidFormComponent } from './main/office/group-details/dynamic-group-forms/social-aid-form/social-aid-form.component';
import { ConsultingFormComponent } from './main/office/group-details/dynamic-group-forms/consulting-form/consulting-form.component';
import { QuillModule } from 'ngx-quill';
@NgModule({
  declarations: [
    MainComponent,
    GroupDetailsComponent,
    OfficeComponent,
    OfficeDetailsComponent,
    MetropoleComponent,
    UserRowComponent,
    GlobalComponent,
    MdmComponent,
    MdmRowComponent,
    NotaryOfficeFormComponent,
    SocialAidFormComponent,
    ConsultingFormComponent,
  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    AuthModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    HomeModule,
    SharedModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }], // custom button values
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],
        ],
      },
    }),
  ],
})
export class AdminModule { }
