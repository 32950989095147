import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private readonly api: ApiService) {}

  public async getVersion(): Promise<string> {
    const backVersion: string = await this.api.get<string>(
      '/api/globalParameters/version',
    );
    return backVersion;
  }
}
