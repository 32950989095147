<div class="row mt-5">
  <div class="col-12 d-flex flex-row-reverse">
    <div ngbDropdown container="body">
      <button
        type="button"
        class="button button-small"
        [disabled]="
          currentUsersForBulkAction && currentUsersForBulkAction.length > 0
            ? false
            : true
        "
        ngbDropdownToggle
      >
        Affectation d'un groupe
      </button>
      <div ngbDropdownMenu>
        <form>
          <div
            class="form-check m-1"
            *ngFor="let group of groups; let index = index"
          >
            <input
              id="{{ group.type + index }}"
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              [value]="group.id"
              [(ngModel)]="groupIdBulkAction"
            />
            <label class="form-check-label" for="{{ group.type + index }}">
              {{ group.label }}
            </label>
          </div>
          <div class="dropdown-divider"></div>
          <button
            type="button"
            class="button button-small"
            (click)="closeDropdown()"
          >
            Annuler
          </button>
          <button
            type="button"
            class="button button-small button-primary"
            [disabled]="!groupIdBulkAction"
            (click)="submitGroupsBulkAction()"
          >
            Valider
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="col-xl-12">
    <table
      aria-describedby="Liste des agents métropolitains et de leur plage alphabétique"
    >
      <thead>
        <tr>
          <th scope="col-1">
            <input
              *ngIf="users && users.length > 0"
              ngbTooltip="Séléctionner tous les utilisateurs"
              type="checkbox"
              class="form-check-input"
              id="chekboxAll"
              (change)="selectAll($event)"
              [checked]="
                users.length > 0 &&
                currentUsersForBulkAction.length > 0 &&
                users.length === currentUsersForBulkAction.length
                  ? true
                  : false
              "
            />
          </th>
          <th scope="col-3" sortable="name" (sort)="onSortAgent($event)">
            Utilisateur
          </th>
          <th scope="col-3">Rôle</th>
          <th scope="col-3">Actions</th>
        </tr>
      </thead>
      <tbody *ngIf="users?.length > 0">
        <tr
          *ngFor="let user of users"
          app-user-row
          [groupIdBulkAction]="groupIdBulkAction"
          [currentUsersForBulkAction]="currentUsersForBulkAction"
          [user]="user"
          [users]="users"
          (usersUpdated)="ngOnInit()"
          (arrayChanged)="updateParentArray($event)"
          [selectAll]="selectAllUsers"
        ></tr>
      </tbody>
      <tbody *ngIf="users?.length == 0" class="">
        <tr>
          <td></td>
          <td>Aucun Utilisateur</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
