import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { LoaderService } from './loader.service';

@Directive({
  selector: '[appLoading]',
})
export class LoadingDirective implements OnInit, OnDestroy {
  private isloadingSubscription: Subscription;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly loaderService: LoaderService,
  ) {}

  public ngOnInit(): void {
    this.isloadingSubscription = this.loaderService.isLoading$.subscribe(
      (isLoading) => {
        if (isLoading) {
          this.showLoader();
        } else {
          this.hideLoader();
        }
      },
    );
  }

  public ngOnDestroy(): void {
    this.isloadingSubscription.unsubscribe();
  }

  private showLoader(): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createComponent(LoadingSpinnerComponent);
  }

  private hideLoader(): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
