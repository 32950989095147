import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  private confirmCallback: (boolean: boolean) => void;
  public question: string;

  constructor(public modal: NgbActiveModal) {}

  public async confirm(question: string): Promise<boolean> {
    this.question = question;
    return new Promise<boolean>((resolve: (value: boolean) => void): void => {
      this.confirmCallback = (result: boolean): void => {
        this.modal.close();
        resolve(result);
      };
    });
  }

  public accept(): void {
    this.confirmCallback(true);
  }

  public refuse(): void {
    this.confirmCallback(false);
  }
}
