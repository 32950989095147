import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class RangeValidator {
    static isValid(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const startControl = group.get('start');
            const endControl = group.get('end');

            if (startControl && endControl) {
                const start = startControl.value.toUpperCase();
                const end = endControl.value.toUpperCase();

                // Si start est supérieur à end, ajoutez une erreur de validation à endControl
                if (start && end && start > end) {
                    endControl.setErrors({ rangeInvalid: true });
                    return { rangeInvalid: true }; // Retourne l'erreur au niveau du groupe
                } else {
                    // Si la validation est réussie, effacez les erreurs précédentes
                    endControl.setErrors(null);
                }
            }

            return null; // Aucune erreur si les conditions ci-dessus ne sont pas remplies
        };
    };
}

