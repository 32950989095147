import {
  Component,
  OnInit,
  forwardRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AdminService } from '../../../admin.service';
import { Mdm } from 'src/admin/mdm.entity';

@Component({
  selector: 'tr[app-mdm-row]',
  templateUrl: './mdm-row.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MdmRowComponent),
      multi: true,
    },
  ],
})

export class MdmRowComponent implements OnInit {
  @Input() mdm: Mdm;
  public rowFormGroup: FormGroup;
  public rowSubmitted = false;
  @Output() mdmUpdated = new EventEmitter();

  constructor(
    private adminService: AdminService,
    private formBuilder: FormBuilder,
  ) { }

  public async ngOnInit() {
    this.rowFormGroup = this.formBuilder.group({
      emailAC: [this.mdm.emailAC, Validators.email],
      emailAPA: [this.mdm.emailAPA, Validators.email],
      emailPCH: [this.mdm.emailPCH, Validators.email],
      emailHeadOfService: [this.mdm.emailHeadOfService, Validators.email],
    });
  }

  public async onRowSubmit() {
    this.rowSubmitted = true;
    if (this.rowFormGroup.invalid) {
      return;
    } else {
      this.mdm.emailAC = this.f.emailAC.value;
      this.mdm.emailAPA = this.f.emailAPA.value;
      this.mdm.emailPCH = this.f.emailPCH.value;
      this.mdm.emailHeadOfService = this.f.emailHeadOfService.value;
      this.adminService.updateMdm(this.mdm);
      this.ngOnInit();
    }
  }

  public get f() {
    return this.rowFormGroup.controls;
  }
}
