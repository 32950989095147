import { PaginationOptions } from './pagination-options';

export class Pagination<T> {
  constructor(
    public readonly items: T[],
    public readonly itemCount: number,
    public readonly totalItems: number,
    public readonly pageCount: number,
    public readonly options: PaginationOptions,
  ) {}

  public static fromJson<T>(json: any) {
    return new Pagination<T>(
      json.items,
      json.itemCount,
      json.totalItems,
      json.pageCount,
      PaginationOptions.fromJson(json.options),
    );
  }
}
