<div class="bg-white p-xl-5 p-2" *ngIf="details">
  <div class="border-bottom border-gray">
    <button
      type="button"
      class="close float-left"
      aria-label="Close"
      (click)="cancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <h3>
      {{ titleModal }}
    </h3>
  </div>
  <select
    id="group-type"
    [(ngModel)]="groupSelect"
    (change)="setValidatorChangeGroup()"
    class="custom-select"
    [disabled]="isModificationMode"
  >
    <option value="" disabled selected>Choisir un groupe :</option>
    <option value="NOTARY_OFFICE">Office notarial</option>
    <option value="SOCIAL_AID">Equipe récupératrice</option>
    <option value="CONSULTING_INT">Consultants internes</option>
    <option value="CONSULTING_EXT">Consultants externes</option>
  </select>

  <app-notary-office-form
    [groupSelect]="groupSelect"
    [detailsFormGroup]="detailsFormGroup"
    [submitted]="submitted"
    [isModificationMode]="isModificationMode"
    [isCreate]="isCreate"
    [CRPCENExists]="CRPCENExists"
    [group]=""
    *ngIf="groupSelect === 'NOTARY_OFFICE' || groupSelect === 'CONSULTING_EXT'"
    (onSubmit)="onSubmit()"
    (cancel)="cancel()"
  ></app-notary-office-form>

  <app-social-aid-form
    [detailsFormGroup]="detailsFormGroup"
    [submitted]="submitted"
    [isModificationMode]="isModificationMode"
    [isCreate]="isCreate"
    [CRPCENExists]="CRPCENExists"
    *ngIf="groupSelect === 'SOCIAL_AID'"
    (onSubmit)="onSubmit()"
    (cancel)="cancel()"
  ></app-social-aid-form>

  <app-consulting-form
    [detailsFormGroup]="detailsFormGroup"
    [submitted]="submitted"
    [isModificationMode]="isModificationMode"
    [isCreate]="isCreate"
    [CRPCENExists]="CRPCENExists"
    *ngIf="groupSelect === 'CONSULTING_INT'"
    (onSubmit)="onSubmit()"
    (cancel)="cancel()"
  ></app-consulting-form>
</div>
