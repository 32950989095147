import {
  AfterViewChecked,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserDto } from 'src/auth/userProfileDto';
import { AuthService } from '../../../auth/auth.service';
import { ConfirmationComponent } from '../../../shared/confirmation/confirmation.component';
import { SortDirection, SortEvent } from '../../../shared/sortable/sort-event';
import { SortableDirective } from '../../../shared/sortable/sortable.directive';
import { AdminService } from '../../admin.service';
import { GroupDetailsComponent } from './notary-details/group-details.component';
import { OfficeDetailsComponent } from './group-details/group-details.component';
import { GroupTableDto } from './office.table.dto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlphabeticalRangeDto } from '../../../shared/dto/alphabeticalRange.dto';
import { RangeValidator } from '../../../shared/validators/range-date.validator';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['../main.component.scss'],
})
export class OfficeComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('myDropdown') myDropdown: NgbDropdown;
  plageErrorExist: string
  blocDroit: HTMLElement;
  positionInitiale = 0; // La position initiale de votre bloc droit
  ngAfterViewChecked() {
    this.blocDroit = document.getElementById('detailsTab');
    if (this.blocDroit && this.positionInitiale === 0) {
      this.positionInitiale = this.blocDroit.getBoundingClientRect().top;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    const positionScroll = window.scrollY;

    if (this.blocDroit) {
      if (positionScroll > this.positionInitiale) {
        this.blocDroit.style.position = 'fixed';
        this.blocDroit.style.top = '1.5rem';
      } else {
        this.blocDroit.style.top = this.positionInitiale + 'px';
      }
    }
  }

  @ViewChild(NgbDropdown)
  private readonly ngbDropdown: NgbDropdown;

  @ViewChildren(SortableDirective)
  headers: QueryList<SortableDirective>;

  public groups: GroupTableDto[] = [];
  public displayedGroups: GroupTableDto[] = [];
  public currentUser: UserDto;
  private groupSaveSub: Subscription | undefined;
  public notaries: UserDto[];
  public currentGroup: GroupTableDto;
  public rowSubmitted = false;

  public isDelClic = false;
  public isDisable = true;
  public confirm = false;

  public searchTerm: string = '';
  public searchTimeout: any;

  public rowFormGroup: FormGroup;
  constructor(
    private adminService: AdminService,
    private readonly modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { }


  public async ngOnInit(): Promise<void> {
    this.rowFormGroup = this.formBuilder.group({
      start: ['', [Validators.pattern('^[A-Za-z]*$')]],
      end: ['', [Validators.pattern('^[A-Za-z]*$')]],
    }, { validators: RangeValidator.isValid });

    this.authService.getCurrentUser().subscribe({
      next: (user) => {
        this.currentUser = user;
      },
    });

    await this.getOffices();
    this.groupSaveSub = this.adminService.officeUpdated$.subscribe(() => {
      this.getOffices();
    });
    this.displayedGroups = this.groups;
  }

  public initFormRangeAlphabet() {
    this.plageErrorExist = null
    this.rowFormGroup = this.formBuilder.group({
      start: ['', [Validators.pattern('^[A-Za-z]*$')]],
      end: ['', [Validators.pattern('^[A-Za-z]*$')]],
    }, { validators: RangeValidator.isValid });
  }

  public getAlphabeticalRanges(ap: AlphabeticalRangeDto[]) {
    return ap.sort((a, b) => a.rangeStart.localeCompare(b.rangeStart));
  }

  public async removeAphabeticalRange(range: AlphabeticalRangeDto, notary: any) {
    const response = await this.adminService.removeAphabeticalRange(range);
    if (response) {
      notary.alphabeticalRanges = notary.alphabeticalRanges.filter(x => x.id !== range.id)
      notary.alphabeticalRanges.sort((a, b) => a.rangeStart.localeCompare(b.rangeStart));
    }
  }

  public async addAphabeticalRange(user: UserDto, rowFormGroup: FormGroup, event) {
    event.preventDefault();
    event.stopPropagation()

    if (rowFormGroup.invalid) {
      return;
    } else {
      const start: string = rowFormGroup.get('start').value.toUpperCase();
      const end: string = rowFormGroup.get('end').value?.toUpperCase();
      const range = {
        userId: user.id,
        rangeStart: this.adjustRangeValue(start),
        rangeEnd: this.adjustRangeValue(end, true),
        groupId: user.groupId,
      } as AlphabeticalRangeDto

      this.adminService.addAlphabeticalRange(range).catch(e => {
        const msg = e.error?.message?.split(':')[0].trim()
        if (msg === 'Error range exist') {
          const user = e.error.message.split(':')[1]
          this.plageErrorExist = user
        }
        event.stopPropagation()
      }).then(x => {
        if (x) {
          this.plageErrorExist = null
          user.alphabeticalRanges.push(x)
          user.alphabeticalRanges.sort((a, b) => a.rangeStart.localeCompare(b.rangeStart))
          rowFormGroup.get('start').setValue('')
          rowFormGroup.get('end').setValue('')
          event.stopPropagation()
        }
      });
    }
  }

  public adjustRangeValue(value: string, endRange: boolean = false): string {
    if (!value) {
      return endRange ? 'ZZZ' : 'AAA';
    }

    value = value.toUpperCase();

    while (value.length < 3) {
      value += endRange ? 'Z' : 'A';
    }

    return value.substring(0, 3);
  }
  public ngOnDestroy(): void {
    if (this.groupSaveSub) {
      this.groupSaveSub.unsubscribe();
    }
  }

  public get f() {
    return this.rowFormGroup.controls;
  }

  public async openModal(structureId?: number): Promise<void> {
    const modal: NgbModalRef = this.modalService.open(OfficeDetailsComponent, {
      windowClass: 'rightModal',
      size: 'lg',
      backdrop: 'static',
    });
    const modalComponent: OfficeDetailsComponent =
      modal.componentInstance as OfficeDetailsComponent;
    await modalComponent.ngOnInit(structureId);
    modal.closed.subscribe({
      next: (result) => {
        if (result) this.ngOnInit();
      },
    });
  }

  public onSort(event: SortEvent): void {
    const column: string = event.column;
    const direction: SortDirection = event.direction;
    this.headers.forEach((header: SortableDirective) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.displayedGroups.sort((a, b) => {
      const valueA = column === 'Referent' ? a['referee'] : a[column];
      const valueB = column === 'Referent' ? b['referee'] : b[column];

      // Check for empty values
      if (valueA === '' || !valueA) {
        return direction === 'asc' ? 1 : -1;
      }
      if (valueB === '' || !valueB) {
        return direction === 'asc' ? -1 : 1;
      }

      // Compare values using localeCompare for correct alphabetical sorting
      return direction === 'asc'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
  }

  public async disableGroup(groupId: number): Promise<void> {
    const confirm: boolean = await this.openConfirmationModal();
    if (confirm) {
      this.isDisable = await this.adminService.disableGroup(groupId);
      if (this.isDisable) {
        this.ngOnInit();
      }
    }
    this.currentGroup = null;
  }

  public getTypeGroup(group: GroupTableDto): string {
    let response;
    if (group.type === 'SOCIAL_AID') {
      response = 'Equipe de récupération';
    } else if (group.type === 'CONSULTING_INT') {
      response = 'Consultants internes';
    } else if (group.type === 'CONSULTING_EXT') {
      response = 'Consultants externes';
    } else if (group.type === 'NOTARY_OFFICE') {
      response = 'Office Notarial';
    }
    return response;
  }

  private async getOffices(column?: string, desc?: boolean): Promise<void> {
    this.groups = await this.adminService.getOffices(column, desc);
    if (!this.currentUser.isAdmin) {
      this.groups = this.groups.filter((office) => {
        return office.id === this.currentUser.groupId;
      });
    } else {
      this.groups = this.groups.filter(
        (group) =>
          !(group.type === 'NOTARY_OFFICE' && group.isActive === false),
      );
    }
  }

  public filterOffices() {
    clearTimeout(this.searchTimeout); // Annule le précédent délai, s'il existe

    this.searchTimeout = setTimeout(() => {
      if (this.searchTerm && this.searchTerm.trim().length > 0) {
        const lowercasedSearchTerm = this.searchTerm.trim().toLowerCase();
        this.displayedGroups = this.groups.filter((office) => {
          const lowercasedLabel = office.label ? office.label.toLowerCase() : '';
          const lowercasedCRPCEN = office.CRPCEN ? office.CRPCEN.toString().toLowerCase() : '';
          const usersMatch = office.users.some((user) => {
            const lowercasedName = user.name ? user.name.toLowerCase() : '';
            const lowercasedEmail = user.email ? user.email.toLowerCase() : '';
            return (
              lowercasedName.includes(lowercasedSearchTerm) ||
              lowercasedEmail.includes(lowercasedSearchTerm)
            );
          });
          return lowercasedLabel.includes(lowercasedSearchTerm) ||
            lowercasedCRPCEN.includes(lowercasedSearchTerm) ||
            usersMatch;
        });
      } else {
        this.displayedGroups = this.groups;
      }
    }, 400);
  }


  public clearSearchTerm() {
    this.searchTerm = '';
    this.displayedGroups = this.groups;
  }

  private async openConfirmationModal(): Promise<boolean> {
    const modal: NgbModalRef = this.modalService.open(ConfirmationComponent, {
      backdrop: 'static',
      centered: true,
    });
    const modalComponent: ConfirmationComponent =
      modal.componentInstance as ConfirmationComponent;
    return modalComponent.confirm(
      'Êtes-vous sûr de vouloir désactiver ce groupe ?',
    );
  }

  public async openGroup(office?: GroupTableDto): Promise<void> {
    this.currentGroup = office;
    if (office && office.referee !== '') {
      this.notaries = office.users;
    }
  }

  public async openModalNotary(
    groupId: number,
    notary?: UserDto,
  ): Promise<void> {
    const modal: NgbModalRef = this.modalService.open(GroupDetailsComponent, {
      windowClass: 'rightModal',
      size: 'lg',
      backdrop: 'static',
    });
    const modalComponent: GroupDetailsComponent =
      modal.componentInstance as GroupDetailsComponent;
    notary
      ? await modalComponent.onInit(groupId, this, notary.id)
      : await modalComponent.onInit(groupId, this);
  }

  public async setIsActiveUserProps(user: UserDto) {
    if (user.isActive) this.confirm = await this.openDelConfirmationModal();
    else this.confirm = true
    if (this.confirm) {
      if (!user.isMetropole) {
        user.isActive = !user.isActive;
      } else {
        user.groupId = null;
      }
      await this.adminService.setIsActiveUser(user);
      this.isDisable = true;
      await this.refreshNotaries();
    }
  }

  public async setReferee(user: UserDto) {
    this.confirm = await this.openRefereeConfirmationModal(
      this.currentUser.isNotary,
    );
    if (this.confirm) {
      user.isReferee = true;
      await this.adminService.setReferee(user);
      if (this.currentUser.isNotary) {
        this.authService.logout();
        this.router.navigate(['/login']);
      } else {
        await this.refreshNotaries();

      }
    }
  }


  private async openDelConfirmationModal(): Promise<boolean> {
    const modal: NgbModalRef = this.modalService.open(ConfirmationComponent, {
      backdrop: 'static',
      centered: true,
    });
    const modalComponent: ConfirmationComponent =
      modal.componentInstance as ConfirmationComponent;
    return modalComponent.confirm(
      'Êtes-vous sûr de vouloir désactiver cet utilisateur ?',
    );
  }

  private async openRefereeConfirmationModal(
    isMetropole: boolean,
  ): Promise<boolean> {
    const modal: NgbModalRef = this.modalService.open(ConfirmationComponent, {
      backdrop: 'static',
      centered: true,
    });
    const modalComponent: ConfirmationComponent =
      modal.componentInstance as ConfirmationComponent;
    const msg = isMetropole
      ? `Êtes-vous sûr de vouloir transférer la gestion de l'Office ? Si oui, vous allez perdre cette responsabilité. Il faudra donc vous reconnecter.`
      : `Êtes-vous sûr de vouloir transférer la gestion de l'Office ?`;
    return modalComponent.confirm(msg);
  }

  public async refreshNotaries(): Promise<boolean> {
    this.notaries = await this.adminService.searchUsers(this.currentGroup.id);
    let group = this.displayedGroups?.find((x: GroupTableDto) => this.currentGroup.id === x.id)
    if (group) {
      group.users = this.notaries
      const referee = this.notaries.find(x => x.isReferee)?.name
      if (referee) group.referee = referee
    }
    return true;
  }

  public async changeRoleSubmit(user: UserDto) {
    if (!user.isAdmin) {
      user.isAdmin = true;
      await this.adminService.updateUser(user);
      await this.refreshNotaries();
    } else {
      user.isAdmin = false;
      await this.adminService.updateUser(user);
      await this.refreshNotaries();
    }
  }

  public async onSortNotary(event: SortEvent): Promise<void> {
    if (event.direction === 'asc') {
      this.notaries.sort((a: any, b: any) => {
        if (a[event.column] > b[event.column]) return 1;
        if (a[event.column] < b[event.column]) return -1;
        return 0;
      });
    } else if (event.direction === 'desc') {
      this.notaries.sort((a: any, b: any) => {
        if (a[event.column] < b[event.column]) return 1;
        if (a[event.column] > b[event.column]) return -1;
        return 0;
      });
    }
  }
}
