import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BeneficiaryDto } from 'src/beneficiaries/beneficiary.dto';
import { FileBufferDto } from '../../folders/files/file.buffer.dto';
import { FileDto } from '../../folders/files/file.dto';
import { FolderDto } from '../../folders/folder.dto';
import { SearchFolderDto } from '../../folders/foldersearch.dto';
import { FolderDisplayDto } from '../../folders/search/folderdisplay.dto';
import { StatusUpdateDto as SearchUpdateDto } from '../../folders/status.update.dto';
import { ApiService } from '../api/api.service';
import { StatsForHomeDto } from '../dto/statsForHome.dto';
import { FolderStatusEnum } from '../enum/folderStatus.enum';
import { SearchStatusEnum } from '../enum/searchStatus.enum';
import { Pagination } from '../pagination/pagination';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class FoldersService {
  constructor(private api: ApiService, private toastService: ToastService) { }

  public async search(
    searchParams: SearchFolderDto,
  ): Promise<Pagination<FolderDisplayDto>> {
    return this.api.postSearchPaginated<FolderDisplayDto, SearchFolderDto>(
      '/api/folders/search',
      searchParams,
    );
  }

  public async findById(id: number): Promise<FolderDto> {
    return this.api.get<FolderDto>('/api/folders/' + id + '/details');
  }

  public async getStatsForHome(): Promise<StatsForHomeDto> {
    return this.api.get<StatsForHomeDto>('/api/folders/stats-for-home/');
  }

  public async saveFile(fileBufferDto: FileBufferDto): Promise<void> {
    try {
      await this.api.post<any, FileBufferDto>(
        '/api/folders/files',
        fileBufferDto,
      );
    } catch (error) {
      if (error.status === HttpStatusCode.PayloadTooLarge) {
        this.toastService.show(
          `Le fichier dépasse la taille limite autorisée par le serveur.`,
          'danger',
        );
        throw new Error('max_file_size');
      } else if (error.status === HttpStatusCode.NotFound) {
        this.toastService.show(`Le dossier n'existe pas`, 'danger');
        throw new Error('Folder not found');
      } else if (error.status === HttpStatusCode.Conflict) {
        this.toastService.show(
          `Le dossier est clos, aucun fichier ne peut être ajouté`,
          'danger',
        );
        throw new Error('Folder not found');
      }
    }
  }

  public async searchBeneficiaryByIodasId(
    idIodas: string,
  ): Promise<number | undefined> {
    return this.api.get('/api/beneficiaries/' + idIodas + '/exist');
  }

  public async resolveAmbiguity(
    idFolder: number,
    status: SearchStatusEnum,
    beneficiaryId?: number,
  ): Promise<void> {
    await this.api.post<void, SearchUpdateDto>(
      '/api/folders/resolve-ambiguity',
      {
        idFolder: idFolder,
        status: status,
        beneficiaryId: beneficiaryId,
      } as SearchUpdateDto,
    );
  }

  public async updateFolderStatus(
    idFolder: number,
    newStatus: FolderStatusEnum,
    statusType: string
  ): Promise<void> {
    await this.api.get<FolderDto>(
      `/api/folders/${idFolder}/update-status/${newStatus}/${statusType}`
    );
  }

  public async deleteFile(fileId: number) {
    return this.api.delete<void>('/api/files/' + fileId);
  }

  public async getAmbiguousBeneficiaries(
    folderId: number,
  ): Promise<BeneficiaryDto[]> {
    return this.api.get<BeneficiaryDto[]>('/api/folders/ambiguous/' + folderId);
  }

  public async getFileById(id: number): Promise<FileDto> {
    return this.api.get<FileDto>('/api/files/' + id);
  }

  public async updateFolder(folder: FolderDto): Promise<FolderDto> {
    return this.api.put<FolderDto, FolderDto>('/api/folders/', folder);
  }

  public async relaunchByMailWaitingPayment(id: number, statusType: string) {
    return this.api.get<void>(`/api/folders/relauchWaitingPayment/${id}/${statusType}`);
  }
}
