export class PaginationOptions {
  constructor(
    public page: number,
    public limit: number,
    public sort?: string,
    public desc: boolean = false,
  ) {}

  public static fromJson(json: any) {
    return new PaginationOptions(json.page, json.limit, json.sort, json.desc);
  }

  public buildUrl(baseUrl: string) {
    let url: string = baseUrl + '?page=' + this.page + '&limit=' + this.limit;
    if (this.sort) {
      url += '&sort=' + this.sort;
      if (this.desc) {
        url += '&desc=true';
      }
    }
    return url;
  }

  public withPage(page: number) {
    return new PaginationOptions(page, this.limit, this.sort, this.desc);
  }

  public withLimit(limit: number) {
    return new PaginationOptions(this.page, limit, this.sort, this.desc);
  }

  public withSort(column: string, desc: boolean) {
    return new PaginationOptions(this.page, this.limit, column, desc);
  }
}
