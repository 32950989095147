import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { ToastService } from '../shared/toast/toast.service';
import { UserDto } from './userProfileDto';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService,
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.authService.isLoggedIn()) {
      let currentUser: UserDto;
      this.authService
        .getCurrentUser()
        .subscribe((user) => (currentUser = user));
      if (route.data && route.data.groups) {
        if (route.data.groups.includes('ADMIN') && currentUser.isAdmin) {
          return true;
        }
        if (
          route.data.groups.includes('METROPOLE') &&
          currentUser.isMetropole
        ) {
          return true;
        }
        if (route.data.groups.includes('REFEREE') && currentUser.isReferee) {
          return true;
        }
        if (
          route.data.groups.includes('CONSULTING_INT') &&
          currentUser.groups.includes('CONSULTING_INT')
        ) {
          return true;
        }
        if (route.data.groups.includes('NOTARY') && currentUser.isNotary) {
          return true;
        }
        this.toastService.show(
          "Vous n'avez pas l'autorisation d'accéder à cette page.",
          'danger',
        );
        this.router.navigate(['/']);
        return false;
      }

      return true;
    }

    // not logged in so redirect to login page with the return url
    if (state.url !== '/login') this.router.navigate(['/login']);
    return false;
  }
}
