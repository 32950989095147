import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public go(href: string) {
    window.location.href = href;
  }
}
