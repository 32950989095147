import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { LoadingDirective } from './loading.directive';

@NgModule({
  declarations: [LoadingSpinnerComponent, LoadingDirective],
  imports: [CommonModule],
  exports: [LoadingDirective],
})
export class LoaderModule {}
