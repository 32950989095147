import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss'],
})
export class GlobalComponent implements OnInit {
  public formGroup: FormGroup;
  public agentMessageLength: number = 0;
  public notaryMessageLength: number = 0;
  public alertMessageLength: number = 0;

  public notaryMessageLimitExceeded: boolean = false;
  public agentMessageLimitExceeded: boolean = false;
  public alertMessageLimitExceeded: boolean = false;

  private isUpdatingContent: boolean = false;

  constructor(private formBuilder: FormBuilder, private adminService: AdminService) { }

  @ViewChildren(QuillEditorComponent) quillEditors: QueryList<QuillEditorComponent>;
  @ViewChild('notaryMessageEditor') notaryMessageEditor: QuillEditorComponent;
  @ViewChild('agentMessageEditor') agentMessageEditor: QuillEditorComponent;
  @ViewChild('alertMessageEditor') alertMessageEditor: QuillEditorComponent;

  public async ngOnInit() {
    this.formGroup = this.formBuilder.group({
      notaryMessage: [''],
      chiefOfficer: [''],
      displayAlert: false,
      alertMessage: [''],
      agentMessage: [''],
    });

    const dto = await this.adminService.getglobalParameters();
    this.formGroup.setValue({
      notaryMessage: dto.notaryMessage,
      chiefOfficer: dto.chiefOfficer,
      displayAlert: dto.displayAlert,
      alertMessage: dto.alertMessage,
      agentMessage: dto.agentMessage
    });
  }

  public async onSubmit() {
    if (!this.isFormValid()) {
      return;
    }
    this.adminService.setGlobalParameters(
      this.formGroup.get('notaryMessage').value,
      this.formGroup.get('chiefOfficer').value,
      this.formGroup.get('displayAlert').value,
      this.formGroup.get('alertMessage').value,
      this.formGroup.get('agentMessage').value,
    );
  }

  onContentChanged(inputName: string, limit: number = 500) {
    if (this.isUpdatingContent) return;

    const editor = this[`${inputName}Editor`]?.quillEditor;
    if (!editor) return;

    const text = editor.getText().trim();
    this[`${inputName}Length`] = text.length;

    const isLimitExceeded = text.length > limit;
    this[`${inputName}LimitExceeded`] = isLimitExceeded;

    if (isLimitExceeded) {
      const excessTextIndex = limit;
      const excessTextLength = text.length - limit;

      this.isUpdatingContent = true;

      // Remove background color from previous excess text
      editor.formatText(0, text.length, { 'background': '' });

      // Add a delay before applying the background color to the excess text
      setTimeout(() => {
        // Apply background color to excess text without truncating the text
        editor.formatText(excessTextIndex, excessTextLength, { 'background': 'rgba(217, 4, 4, 0.6)' });

        // Use scrollSelectionIntoView to scroll to the bottom
        editor.scrollSelectionIntoView();

        this.isUpdatingContent = false;
      }, 0);
    }
  }

  isFormValid(): boolean {
    return !this.notaryMessageLimitExceeded && !this.agentMessageLimitExceeded && !this.alertMessageLimitExceeded;
  }
}
