<div class="row mt-5">
  <div class="col-12 col-xl-6 mx-auto">
    <form [formGroup]="forgetPwd" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col">
          <p class="text-secondary">
            <span class="list-style">1</span>Entrez votre adresse mail
            ci-dessous.
          </p>
          <p class="text-secondary">
            <span class="list-style">2</span>Vous recevrez un mail si cette
            adresse mail est rattaché à un compte utilisateur.
          </p>
          <p class="text-secondary">
            <span class="list-style">3</span>Cliquez sur le lien, vous pourrez
            choisir votre nouveau mot de passe.
          </p>
          <p class="text-secondary">
            <span class="list-style">4</span>Après validation de votre nouveau
            mot de passe, vous pouvez vous connecter !
          </p>
        </div>
      </div>

      <div class="form-group text-secondary">
        <label>Adresse email</label>
        <input
          type="text"
          class="form-control"
          formControlName="email"
          onkeyup="this.value=this.value.toLowerCase();"
          [class.is-invalid]="submitted && forgetPwd.controls?.email?.invalid"
        />
        <small
          *ngIf="submitted && forgetPwd.controls?.email?.errors?.required"
          class="form-text text-danger"
          >L'adresse email est requise.</small
        >
        <small
          *ngIf="submitted && forgetPwd.controls?.email?.errors?.email"
          class="form-text text-danger"
          >L'adresse email est invalide.</small
        >
      </div>
      <div class="row">
        <div class="col-12 col-lg-6 col-xl-6 offset-lg-6 offset-xl-6">
          <button type="submit" class="button button-primary">Envoyer</button>
        </div>
      </div>
      <div class="row mt-3" *ngIf="hasForgetFailed">
        <div class="col">
          <ngb-alert type="danger" (close)="resetForgetFailed()"
            >L'envoi du mail de réinitialisation du mot de passe
            impossible.</ngb-alert
          >
        </div>
      </div>
    </form>
  </div>
</div>
